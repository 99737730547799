import type { IntegrationSummary } from "@brm/schema-types/types.js"
import { displayPersonName } from "@brm/util/names.js"
import {
  Avatar,
  AvatarGroup,
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  type CardProps,
} from "@chakra-ui/react"
import { useRef, useState, type FunctionComponent } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import {
  useGetUserV1EmailDelegatesQuery,
  useGetUserV1WhoamiQuery,
  usePostUserV1EmailDelegatesMutation,
} from "../../app/services/generated-api.js"
import ObjectSelectorFilter from "../../components/DataTable/SchemaFilter/FilterTypes/ObjectSelectorFilter.js"
import { Link } from "../../components/Link.js"
import { IntegrationProviderIcon } from "../../components/icons/IntegrationProviderIcon.js"
import {
  AlertTriangleIcon,
  HelpIcon,
  MailIcon,
  MoreMenuIcon,
  PlusIcon,
  SearchIcon,
  UserIcon,
} from "../../components/icons/icons.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import CreateIntegrationModal from "./CreateIntegrationModal.js"
import { DeleteIntegrationConfirmationModal } from "./DeleteIntegrationConfirmationModal.js"

export const ConnectGmailCard: FunctionComponent<
  {
    connectedIntegrations?: IntegrationSummary[]
  } & CardProps
> = ({ connectedIntegrations, ...cardProps }) => {
  const intl = useIntl()

  const { data: currentDelegates } = useGetUserV1EmailDelegatesQuery()
  const currentDelegateIds = new Set(currentDelegates?.map((d) => d.id) ?? [])

  const searchInputRef = useRef<HTMLInputElement>(null)
  const [selectedIntegration, setSelectedIntegration] = useState<IntegrationSummary | null>(null)

  const connectModal = useDisclosure()
  const reconnectModal = useDisclosure()
  const disconnectModal = useDisclosure()

  return (
    <>
      <Card variant="outline" padding={0} {...cardProps}>
        <CardBody>
          <Stack color="gray.700" fontSize="sm">
            <HStack>
              <IntegrationProviderIcon integration={{ provider: "gmail_oauth" }} boxSize={6} />
              <Text fontWeight="bold" fontSize="md">
                <FormattedMessage
                  id="integrations.page.connect.gmail.title"
                  description="Title of the Gmail integration card"
                  defaultMessage="Gmail"
                />
              </Text>
              <Spacer />
              <Button onClick={connectModal.onOpen} colorScheme="brand" flexShrink={0}>
                <FormattedMessage
                  id="integrations.page.connect.button"
                  description="Button text of integration page connect integration option"
                  defaultMessage="Add Email"
                />
              </Button>
            </HStack>
            {connectedIntegrations && connectedIntegrations.length > 0 ? (
              <>
                <FormattedMessage
                  id="integrations.page.connect.gmail.description"
                  description="Description of the Gmail integration card"
                  defaultMessage="BRM is automagically finding vendor documents from the following emails:"
                />
                <Card boxShadow={0} marginTop={2} overflow="hidden">
                  {connectedIntegrations.map((connectedIntegration) => {
                    return (
                      <HStack
                        key={connectedIntegration.id}
                        padding={2}
                        backgroundColor={
                          connectedIntegration.status === "disconnected" || connectedIntegration.status === "deleting"
                            ? "warning.50"
                            : "white"
                        }
                      >
                        <Icon as={MailIcon} />
                        <Text as="span" color="gray.800" fontSize="xs" fontWeight="medium" isTruncated>
                          {connectedIntegration.email}
                        </Text>
                        {(connectedIntegration.status === "disconnected" ||
                          connectedIntegration.status === "deleting") && (
                          <Tooltip
                            label={
                              connectedIntegration.status === "disconnected"
                                ? intl.formatMessage({
                                    id: "integrations.page.disconnected.tooltip",
                                    description: "Tooltip text of integration page disconnected integration option",
                                    defaultMessage:
                                      "The integration has failed and won’t retrieve new data until it is reconnected.",
                                  })
                                : intl.formatMessage({
                                    id: "integrations.page.deleting.tooltip",
                                    description: "Tooltip text of integration page deleting integration option",
                                    defaultMessage: "The integration is being disconnected",
                                  })
                            }
                            shouldWrapChildren
                          >
                            <Icon as={AlertTriangleIcon} color="warning.600" />
                          </Tooltip>
                        )}
                        <Spacer />
                        <Text as="span" color="gray.600" fontSize="xs" isTruncated>
                          {connectedIntegration.detail_text}
                        </Text>
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            size="xs"
                            icon={<Icon as={MoreMenuIcon} />}
                            aria-label={intl.formatMessage({
                              id: "integrations.page.connect.connected.tooltip",
                              description: "Tooltip text of integration page connected integration option",
                              defaultMessage: "Connected",
                            })}
                            variant="ghost"
                          />
                          <Portal>
                            <MenuList>
                              {connectedIntegration.status === "disconnected" && (
                                <MenuItem
                                  onClick={() => {
                                    setSelectedIntegration(connectedIntegration)
                                    reconnectModal.onOpen()
                                  }}
                                >
                                  <FormattedMessage
                                    id="integrations.page.connect.reconnect"
                                    description="Button text of integration page connect integration option"
                                    defaultMessage="Reconnect"
                                  />
                                </MenuItem>
                              )}
                              <MenuItem
                                onClick={() => {
                                  setSelectedIntegration(connectedIntegration)
                                  disconnectModal.onOpen()
                                }}
                              >
                                <FormattedMessage
                                  id="integrations.page.connect.disconnect"
                                  description="Button text of integration page connect integration option"
                                  defaultMessage="Disconnect"
                                />
                              </MenuItem>
                            </MenuList>
                          </Portal>
                        </Menu>
                      </HStack>
                    )
                  })}
                </Card>
              </>
            ) : (
              <FormattedMessage
                id="integrations.page.connect.gmail.description.noIntegrations"
                description="Description of the Gmail integration card with no connected integrations"
                defaultMessage="Connect your Gmail account to allow BRM to gather your contracts automagically."
              />
            )}
          </Stack>
        </CardBody>
        {connectedIntegrations && (
          <>
            <Divider borderColor="gray.300" />
            <CardFooter paddingY={2} alignItems="center">
              {connectedIntegrations && connectedIntegrations.length > 0 && (
                <>
                  <Popover isLazy initialFocusRef={searchInputRef} placement="bottom-start">
                    <PopoverTrigger>
                      <Button variant="ghost" fontSize="sm" leftIcon={<Icon as={PlusIcon} />}>
                        <FormattedMessage
                          id="integrations.page.connect.addDelegates"
                          defaultMessage="Add delegates"
                          description="Label for button to add delegates to integration"
                        />
                      </Button>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent>
                        <ConnectGmailDelegateSelector
                          searchInputRef={searchInputRef}
                          currentDelegateIds={currentDelegateIds}
                        />
                      </PopoverContent>
                    </Portal>
                  </Popover>
                  <Tooltip
                    shouldWrapChildren
                    fontWeight="light"
                    label={intl.formatMessage({
                      defaultMessage:
                        "<strong>Add delegates to review your agreements</strong><br></br>Delegate reviewers to review agreements found in your Gmail. Only users with the legal role can be delegated. You can add or remove them at any time.",
                      id: "integrations.page.connect.addDelegates.help",
                      description: "Tooltip text for add delegates button",
                    })}
                  >
                    <Icon as={HelpIcon} marginLeft={1} />
                  </Tooltip>
                  {currentDelegates && currentDelegates.length > 0 && (
                    <AvatarGroup spacing={-1}>
                      {currentDelegates.map((user) => (
                        <Avatar
                          key={user.id}
                          src={getPublicImageGcsUrl(user.profile_image?.gcs_file_name) || ""}
                          name={displayPersonName(user, intl)}
                          icon={<Icon as={UserIcon} />}
                        />
                      ))}
                    </AvatarGroup>
                  )}
                </>
              )}
              <Spacer />
              <Button as={Link} fontSize="sm" variant="link" color="brand.500" to="/agreements?source=email">
                <FormattedMessage
                  id="integrations.page.goToAgreements.button"
                  description="Button text of integration page go to agreements option"
                  defaultMessage="Go to agreements"
                />
              </Button>
            </CardFooter>
          </>
        )}
      </Card>

      <CreateIntegrationModal integrationProvider="gmail_oauth" {...connectModal} />
      {selectedIntegration && disconnectModal.isOpen && (
        <DeleteIntegrationConfirmationModal {...disconnectModal} integrationBeingDeleted={selectedIntegration} />
      )}
      {selectedIntegration && reconnectModal.isOpen && (
        <CreateIntegrationModal
          integrationId={selectedIntegration.id}
          integrationProvider="gmail_oauth"
          {...reconnectModal}
        />
      )}
    </>
  )
}

const ConnectGmailDelegateSelector: FunctionComponent<{
  searchInputRef: React.LegacyRef<HTMLInputElement>
  currentDelegateIds: Set<string>
}> = ({ searchInputRef, currentDelegateIds }) => {
  const [searchInput, setSearchInput] = useState("")
  const [updateDelegates] = usePostUserV1EmailDelegatesMutation()
  const { data: whoami } = useGetUserV1WhoamiQuery()

  return (
    <>
      <Flex background="white" zIndex={1} borderBottomWidth="1px" p={2} gap={1} alignItems="center">
        <InputGroup>
          <InputLeftElement>
            <Icon as={SearchIcon} />
          </InputLeftElement>
          <Input ref={searchInputRef} type="search" onChange={(e) => setSearchInput(e.target.value)} autoFocus />
        </InputGroup>
      </Flex>
      <ObjectSelectorFilter
        searchInput={searchInput}
        excludedValues={whoami ? new Set([whoami.id]) : undefined}
        onChange={async (value) => {
          const newDelegateIds = new Set(currentDelegateIds)
          if (currentDelegateIds.has(value)) {
            newDelegateIds.delete(value)
            await updateDelegates({
              body: { delegate_ids: Array.from(newDelegateIds) },
            })
          } else {
            newDelegateIds.add(value)
            await updateDelegates({
              body: { delegate_ids: Array.from(newDelegateIds) },
            })
          }
        }}
        selectedValues={currentDelegateIds}
        selectable={{
          objectType: "User",
          permission: {
            value: "legal:update",
            includeAll: false,
          },
        }}
      />
    </>
  )
}
