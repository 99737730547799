import { isValidFieldName } from "@brm/schema-helpers/field-names.js"
import type { CustomizableObjectType, FieldConfig } from "@brm/schema-types/types.js"
import { dereferenceSchema } from "@brm/util/schema.js"
import { Box, Button, Divider, Flex, FormControl, FormLabel, HStack, Icon, Tooltip, useToast } from "@chakra-ui/react"
import type { JSONSchema, JSONSchemaObject } from "@json-schema-tools/meta-schema"
import { Select } from "chakra-react-select"
import { useEffect, useId, useMemo, useState, type FunctionComponent } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import type { ReadonlyDeep } from "type-fest"
import type { FieldConfigMinimal } from "../../../app/services/generated-api.js"
import {
  useDeleteSchemaV1ByObjectTypeFieldsAndFieldNameMutation,
  usePutSchemaV1ByObjectTypeFieldsAndFieldNameMutation,
} from "../../../app/services/generated-api.js"
import { HelpIcon } from "../../../components/icons/icons.js"
import { getAPIErrorMessage } from "../../../util/error.js"
import { CriterionConfigForm } from "./CriterionConfigForm.js"
import { CriterionProvenance } from "./CriterionProvenance.js"
import { DEFAULT_CRITERION_FORM_STATE } from "./util.js"

export const CriterionInspector: FunctionComponent<{
  objectType: CustomizableObjectType
  fieldConfig?: FieldConfig
  defs: Record<string, ReadonlyDeep<JSONSchema>>
  onCriterionDeleted: () => void
  onCriterionCreated?: (newCriterion: FieldConfigMinimal) => void
  fieldNames?: ReadonlySet<string>
}> = ({ objectType, fieldConfig, defs, onCriterionDeleted, onCriterionCreated, fieldNames }) => {
  const toast = useToast()
  const intl = useIntl()
  const isNewCriterion = fieldConfig === undefined
  const [isEnabled, setIsEnabled] = useState(fieldConfig?.is_enabled ?? true)

  useEffect(() => {
    setIsEnabled(fieldConfig?.is_enabled ?? true)
  }, [fieldConfig?.is_enabled])

  const [upsertFieldConfig, upsertFieldConfigStatus] = usePutSchemaV1ByObjectTypeFieldsAndFieldNameMutation()
  const [deleteCustomField, deleteCustomFieldStatus] = useDeleteSchemaV1ByObjectTypeFieldsAndFieldNameMutation()

  const dereferencedConfig = useMemo(() => {
    if (!fieldConfig) return null
    const schema = dereferenceSchema(fieldConfig.field_schema as JSONSchemaObject, defs) as JSONSchemaObject
    return { ...fieldConfig, field_schema: schema }
  }, [defs, fieldConfig])

  const enableOptions = [
    {
      value: true,
      label: (
        <FormattedMessage
          defaultMessage="Yes"
          description="Option for enabling a criterion"
          id="settings.criteria.object.enableCriterionYes"
        />
      ),
    },
    {
      value: false,
      label: (
        <FormattedMessage
          defaultMessage="No"
          description="Option for disabling a criterion"
          id="settings.criteria.object.enableCriterionNo"
        />
      ),
    },
  ]

  const onValidSubmit = async (fieldConfigInput: FieldConfigMinimal): Promise<void> => {
    try {
      if (isNewCriterion) {
        const result = await upsertFieldConfig({
          objectType,
          fieldName: fieldConfigInput.field_name,
          fieldConfigInput,
        }).unwrap()

        toast({
          status: "success",
          description: (
            <FormattedMessage
              defaultMessage="Created new criterion"
              description="Created new criterion toast message"
              id="settings.criteria.object.criterionCreated"
            />
          ),
        })

        if (onCriterionCreated) {
          // Cast the result to FieldConfig to satisfy TypeScript
          onCriterionCreated(result)
        }
      } else {
        await upsertFieldConfig({
          objectType,
          fieldName: fieldConfigInput.field_name,
          fieldConfigInput,
        }).unwrap()

        toast({
          status: "success",
          description: (
            <FormattedMessage
              defaultMessage="Updated criterion"
              description="Updated criterion toast message"
              id="settings.criteria.object.criterionUpdated"
            />
          ),
        })
      }
    } catch (err) {
      toast({
        status: "error",
        description: getAPIErrorMessage(err) ?? (
          <FormattedMessage
            defaultMessage="An error occurred updating the criterion"
            description="Error message when updating a criterion fails"
            id="settings.criteria.object.updateCriterionFailed"
          />
        ),
      })
    }
  }

  const onDelete = async () => {
    if (!fieldConfig) return
    try {
      await deleteCustomField({
        fieldName: fieldConfig.field_name,
        objectType,
      }).unwrap()
      toast({
        status: "success",
        description: (
          <FormattedMessage
            defaultMessage="Deleted criterion"
            description="Deleted criterion toast message"
            id="settings.criteria.object.criterionDeleted"
          />
        ),
      })
      onCriterionDeleted()
    } catch (err) {
      toast({
        status: "error",
        description: getAPIErrorMessage(err) ?? (
          <FormattedMessage
            defaultMessage="An error occurred deleting the criterion"
            description="Error message when deleting a criterion fails"
            id="settings.criteria.object.deleteCriterionFailed"
          />
        ),
      })
    }
  }

  const onToggleEnabled = async (newIsEnabled: boolean) => {
    if (!fieldConfig) return
    setIsEnabled(newIsEnabled)
    try {
      await upsertFieldConfig({
        objectType,
        fieldName: fieldConfig.field_name,
        fieldConfigInput: {
          ...fieldConfig,
          is_enabled: newIsEnabled,
        },
      }).unwrap()
      toast({
        status: "success",
        description: (
          <FormattedMessage
            defaultMessage={newIsEnabled ? "Criterion enabled" : "Criterion disabled"}
            description="Toast message for enabling/disabling a criterion"
            id="settings.criteria.object.criterionToggled"
          />
        ),
      })
    } catch (err) {
      setIsEnabled(!newIsEnabled)
      toast({
        status: "error",
        description: getAPIErrorMessage(err) ?? (
          <FormattedMessage
            defaultMessage="An error occurred updating the criterion"
            description="Error message when updating a criterion fails"
            id="settings.criteria.object.updateCriterionFailed"
          />
        ),
      })
    }
  }

  const formId = `criterion-form-${useId()}`

  return (
    <Flex flexDirection="column" gap={4}>
      {!isNewCriterion &&
        // LegalAgreement is a special case where the field is not configurable unless it is custom
        (objectType !== "LegalAgreement" || fieldConfig.is_custom) &&
        dereferencedConfig?.field_schema.configurable !== false && (
          <FormControl>
            <FormLabel htmlFor="isEnabled">
              <FormattedMessage
                defaultMessage="Enabled"
                description="Label for the criterion enable/disable dropdown"
                id="settings.criteria.object.enableCriterion"
              />{" "}
              <Tooltip
                label={
                  <FormattedMessage
                    defaultMessage="You can turn fields on or off depending on if these criteria are relevant to your BRM. For example you should only enable PCI DSS if you are a payment provider or processor that has to maintain PCI compliance."
                    description="Tooltip explaining the consequence of disabling a criterion"
                    id="settings.criteria.object.enableCriterionTooltip"
                  />
                }
                shouldWrapChildren
              >
                <Icon as={HelpIcon} lineHeight="inherit" boxSize="1lh" verticalAlign="bottom" />
              </Tooltip>
            </FormLabel>
            <Select
              id="isEnabled"
              value={enableOptions.find((option) => option.value === isEnabled)}
              onChange={(selectedOption) => onToggleEnabled(selectedOption?.value ?? false)}
              options={enableOptions}
              isClearable={false}
            />
          </FormControl>
        )}
      <CriterionConfigForm
        fieldConfig={fieldConfig}
        onValidSubmit={onValidSubmit}
        id={formId}
        defaultValues={isNewCriterion ? { ...DEFAULT_CRITERION_FORM_STATE, object_type: objectType } : undefined}
        validateFieldName={(fieldName) => {
          if (fieldNames?.has(fieldName)) {
            return intl.formatMessage({
              defaultMessage:
                "There is already a criterion with this or a similar name. Consider using the existing field or choosing a different name.",
              description: "Error message for duplicate criterion name",
              id: "settings.criteria.newCriterionModal.duplicateName",
            })
          }
          if (!isValidFieldName(fieldName)) {
            return intl.formatMessage({
              defaultMessage: "Invalid field name.",
              description: "Error message for invalid field name",
              id: "settings.criteria.newCriterionModal.invalidFieldName",
            })
          }
          return true
        }}
      />
      <Divider />
      <HStack>
        <Box color="gray.600" fontSize="sm" mr="auto">
          {!isNewCriterion && fieldConfig && <CriterionProvenance fieldConfig={fieldConfig} />}
        </Box>
        {(isNewCriterion || dereferencedConfig?.field_schema.configurable !== false) && (
          <>
            {!isNewCriterion && fieldConfig?.is_custom && (
              <Button
                colorScheme="error"
                variant="subtleOutlined"
                onClick={onDelete}
                isLoading={deleteCustomFieldStatus.isLoading}
              >
                <FormattedMessage
                  defaultMessage="Delete"
                  description="Button label for deleting a criterion"
                  id="settings.criteria.object.deleteCriterion"
                />
              </Button>
            )}
            <Button colorScheme="brand" type="submit" form={formId} isLoading={upsertFieldConfigStatus.isLoading}>
              {isNewCriterion ? (
                <FormattedMessage
                  defaultMessage="Create"
                  description="Button label for creating a new criterion"
                  id="settings.criteria.object.createCriterion"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Save"
                  description="Button label for saving a criterion"
                  id="settings.criteria.object.saveCriterion"
                />
              )}
            </Button>
          </>
        )}
      </HStack>
    </Flex>
  )
}
