import type {
  FieldMetadata,
  FieldMetadataWithSuggestions,
  VendorOption,
  VendorOptionWithTools,
} from "@brm/schema-types/types.js"
import { Box, HStack, Icon, Text, chakra } from "@chakra-ui/react"
import type { ControlProps, OptionProps, Props } from "chakra-react-select"
import { Select, chakraComponents } from "chakra-react-select"
import { forwardRef, useImperativeHandle, useRef, useState, type Ref } from "react"
import { useIntl } from "react-intl"
import { useGetVendorV1PickerOptionsQuery } from "../../app/services/generated-api.js"
import { isNewOption } from "../../util/form.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import OptionWithFieldSource from "../SchemaForm/OptionWithFieldSource.js"
import type { SchemaFormFieldApproval, ValueWithSource } from "../SchemaForm/types.js"
import { VendorLogo } from "../icons/Logo.js"
import { PlusIcon } from "../icons/icons.js"

type VendorOptionPickerProps = {
  onChange?: (value: VendorOptionWithTools, fieldSource?: FieldMetadata) => void
  suggestions?: ValueWithSource<VendorOptionWithTools>[]
  value: VendorOption | null
  fieldMetadata?: FieldMetadataWithSuggestions
  fieldApproval?: SchemaFormFieldApproval
} & Omit<Props<ValueWithSource<VendorOptionWithTools | null>, false>, "onChange" | "value">

const Option = ({ children, ...props }: OptionProps<ValueWithSource<VendorOptionWithTools | null>, false>) => {
  const intl = useIntl()
  const fieldSources = props.data.field_sources

  return props.data.value?.vendor_listing_id !== "new" ? (
    <chakraComponents.Option {...props}>
      <VendorLogo boxSize={6} logo={getPublicImageGcsUrl(props.data.value?.image_asset?.gcs_file_name)} />
      <OptionWithFieldSource fieldSources={fieldSources}>
        <HStack flexGrow={1} minWidth={0} justifyContent="space-between">
          <Text flexShrink={1} minWidth={0} textOverflow="ellipsis" overflow="hidden">
            {children}
          </Text>
          {!props.data.value?.id && (
            <chakra.span color="gray.600" fontSize="xs" marginTop="auto" flexShrink={0}>
              {intl.formatMessage({
                defaultMessage: "Add to your organization",
                description: "Support text to indicate a net-new vendor",
                id: "Option.span",
              })}
            </chakra.span>
          )}
        </HStack>
      </OptionWithFieldSource>
    </chakraComponents.Option>
  ) : (
    <chakraComponents.Option {...props}>
      <Icon boxSize={5} as={PlusIcon} /> {children}
    </chakraComponents.Option>
  )
}

const Control = ({ children, ...props }: ControlProps<ValueWithSource<VendorOptionWithTools | null>, false>) => {
  const { inputValue } = props.selectProps
  const { hasValue } = props
  const hasInput = inputValue.length > 0
  const data = props.getValue()
  return (
    <chakraComponents.Control {...props}>
      <chakra.span ml={3}>
        {hasValue && hasInput ? <VendorLogo boxSize={6} /> : null}
        {hasValue && data[0] && !hasInput ? (
          <VendorLogo boxSize={6} logo={getPublicImageGcsUrl(data[0].value?.image_asset?.gcs_file_name)} />
        ) : null}
      </chakra.span>
      {children}
    </chakraComponents.Control>
  )
}

export const VendorOptionPicker = forwardRef(function VendorOptionPicker(
  props: VendorOptionPickerProps,
  ref: Ref<HTMLInputElement | null>
) {
  const { onChange, value, suggestions, fieldMetadata, fieldApproval, ...rest } = props

  const inputRef = useRef<HTMLInputElement | null>(null)
  useImperativeHandle(ref, () => inputRef.current)

  const [query, setQuery] = useState("")

  const { data, isLoading } = useGetVendorV1PickerOptionsQuery({ search: query })
  const vendorList: ValueWithSource<VendorOption>[] =
    data
      // Filter out fetched vendor if it is already in suggestions
      ?.filter(
        (vendor) =>
          !suggestions?.some((suggestedVendor) => suggestedVendor.value.vendor_listing_id === vendor.vendor_listing_id)
      )
      .map((vendor) => ({
        value: vendor,
        fieldSources: [],
      })) || []

  return (
    <Box flexGrow={1}>
      <Select<ValueWithSource<VendorOptionWithTools | null>>
        chakraStyles={{
          valueContainer: (styles) => ({ ...styles, paddingLeft: "4px" }),
          option: (styles, { data }) => {
            const { isNew, colorScheme } = isNewOption(data.field_sources, fieldMetadata, fieldApproval)
            return {
              ...styles,
              display: "flex",
              gap: "0.5rem",
              ...(isNew && { backgroundColor: `${colorScheme}.50` }),
            }
          },
        }}
        styles={{
          menuPortal: (styles) => ({ ...styles, zIndex: "var(--chakra-zIndices-dropdown)" }),
        }}
        openMenuOnFocus={true}
        menuPortalTarget={document.body}
        options={[...(suggestions ?? []), ...vendorList]}
        isLoading={isLoading}
        getOptionLabel={(option) => option.value?.display_name || ""}
        getOptionValue={(option) => option.value?.vendor_listing_id || ""}
        onChange={(option) => option?.value && onChange?.(option?.value, option?.field_sources?.[0])}
        value={value && { value }}
        inputValue={query}
        onInputChange={(val) => setQuery(val)}
        components={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Option,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Control,
        }}
        ref={(select) => {
          inputRef.current = select?.inputRef ?? null
        }}
        {...rest}
      />
    </Box>
  )
})
