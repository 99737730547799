import type { FieldMetadataWithSuggestions, ToolOptionWithVendor } from "@brm/schema-types/types.js"
import { Box, HStack, Icon, Text, chakra } from "@chakra-ui/react"
import type { ControlProps, CreatableProps, GroupBase, OptionProps, Props, SelectInstance } from "chakra-react-select"
import { CreatableSelect, Select, chakraComponents } from "chakra-react-select"
import { useRef, useState } from "react"
import type { RefCallBack } from "react-hook-form"
import { useIntl } from "react-intl"
import { useGetToolV1PickerOptionsQuery } from "../../app/services/generated-api.js"
import { isNewOption } from "../../util/form.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import OptionWithFieldSource from "../SchemaForm/OptionWithFieldSource.js"
import type { SchemaFormFieldApproval, ValueWithSource } from "../SchemaForm/types.js"
import { ToolLogo } from "../icons/Logo.js"
import { PlusIcon } from "../icons/icons.js"
import { chakraStylesForProfilePicturePickerOption } from "./constants.js"

interface ToolOptionPickerProps extends Props<ToolOptionWithVendor, false> {}

export function ToolOptionPicker(props: ToolOptionPickerProps) {
  const { onChange, value } = props

  const [search, setSearch] = useState("")
  const ref = useRef<SelectInstance<ToolOptionWithVendor, false>>(null)

  const { data, isLoading } = useGetToolV1PickerOptionsQuery({ search })

  return (
    <Box flexGrow={1}>
      <Select<ToolOptionWithVendor>
        options={data || []}
        isLoading={isLoading}
        chakraStyles={chakraStylesForProfilePicturePickerOption((toolOption) =>
          getPublicImageGcsUrl(toolOption.image_asset?.gcs_file_name)
        )}
        getOptionLabel={(toolOption) => toolOption.display_name}
        getOptionValue={(toolOption) => toolOption.tool_listing_id}
        onChange={onChange}
        value={value}
        inputValue={search}
        onInputChange={setSearch}
        ref={ref}
        menuPortalTarget={document.body}
        {...props}
      />
    </Box>
  )
}

export type CreatableToolOptionPickerProps = {
  allowCreate: boolean
  suggestions?: ValueWithSource<ToolOptionWithVendor>[]
  fieldMetadata?: FieldMetadataWithSuggestions
  fieldApproval?: SchemaFormFieldApproval
  inputRef?: RefCallBack
} & CreatableProps<
  ValueWithSource<ToolOptionWithVendor | null>,
  false,
  GroupBase<ValueWithSource<ToolOptionWithVendor | null>>
>

const Control = ({ children, ...props }: ControlProps<ValueWithSource<ToolOptionWithVendor | null>, false>) => {
  const { inputValue } = props.selectProps
  const { hasValue } = props
  const hasInput = inputValue.length > 0
  const data = props.getValue()
  return (
    <chakraComponents.Control {...props}>
      <chakra.span ml={3}>
        {hasValue && hasInput ? <ToolLogo boxSize={6} /> : null}
        {hasValue && data[0] && !hasInput ? (
          <ToolLogo boxSize={6} logo={getPublicImageGcsUrl(data[0].value?.image_asset?.gcs_file_name)} />
        ) : null}
      </chakra.span>
      {children}
    </chakraComponents.Control>
  )
}

const Option = ({ children, ...props }: OptionProps<ValueWithSource<ToolOptionWithVendor | null>, false>) => {
  const intl = useIntl()
  const fieldSources = props.data.field_sources

  return props.data.value?.tool_listing_id !== "new" ? (
    <chakraComponents.Option {...props}>
      <ToolLogo boxSize={6} logo={getPublicImageGcsUrl(props.data.value?.image_asset?.gcs_file_name)} />
      <OptionWithFieldSource fieldSources={fieldSources}>
        <HStack flexGrow={1} minWidth={0} justifyContent="space-between">
          <Text flexShrink={1} minWidth={0} textOverflow="ellipsis" overflow="hidden">
            {children}
          </Text>
          {!props.data.value?.id && (
            <chakra.span color="gray.600" fontSize="xs" marginTop="auto" flexShrink={0}>
              {intl.formatMessage({
                defaultMessage: "Add to your organization",
                description: "Support text to indicate a net-new tool",
                id: "Option.span",
              })}
            </chakra.span>
          )}
        </HStack>
      </OptionWithFieldSource>
    </chakraComponents.Option>
  ) : (
    <chakraComponents.Option {...props}>
      <Icon boxSize={5} as={PlusIcon} /> {children}
    </chakraComponents.Option>
  )
}

export function ToolOptionPickerCreatable(props: CreatableToolOptionPickerProps) {
  const { value, suggestions, fieldMetadata, fieldApproval, inputRef } = props

  const [search, setSearch] = useState("")
  const { data, isLoading } = useGetToolV1PickerOptionsQuery({ search })

  const toolList: ValueWithSource<ToolOptionWithVendor>[] =
    data
      // Filter out fetched tool if it is already in suggestions
      ?.filter(
        (tool) => !suggestions?.some((suggestedTool) => suggestedTool.value.tool_listing_id === tool.tool_listing_id)
      )
      .map((tool) => ({
        value: tool,
        fieldSources: [],
      })) || []
  return (
    <Box flexGrow={1}>
      <CreatableSelect<ValueWithSource<ToolOptionWithVendor | null>>
        options={[...(suggestions ?? []), ...toolList]}
        isValidNewOption={(newToolName: string) => {
          // do not allow creating a new tool with an empty string
          if (newToolName === "") {
            return false
          }

          // It is common that tools share the same display name, thus we need to allow users
          // to create a net new tool, even if there is an existing tool with the same display_name.
          // If all of the current options contain the same key, display the new tool create option.
          return toolList.every((toolListing) =>
            toolListing.value.display_name.toLowerCase().includes(newToolName.toLowerCase())
          )
        }}
        ref={(select) => {
          inputRef?.(select?.inputRef ?? null)
        }}
        openMenuOnFocus={true}
        isLoading={isLoading}
        components={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Control,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Option,
        }}
        chakraStyles={{
          valueContainer: (styles) => ({ ...styles, paddingLeft: "4px" }),
          option: (styles, { data }) => {
            const { isNew, colorScheme } = isNewOption(data.field_sources, fieldMetadata, fieldApproval)
            return {
              ...styles,
              display: "flex",
              gap: "0.5rem",
              ...(isNew && {
                backgroundColor: `${colorScheme}.50`,
              }),
            }
          },
        }}
        menuPortalTarget={document.body}
        getOptionLabel={({ value }) => value?.display_name ?? ""}
        getOptionValue={({ value }) => value?.tool_listing_id || ""}
        getNewOptionData={(inputValue) => {
          return {
            value: {
              display_name: inputValue,
              tool_listing_id: "new",
              description: null,
              website: null,
              image_asset: null,
              id: null,
              object_type: "Tool",
            },
          }
        }}
        value={value}
        inputValue={search}
        onInputChange={setSearch}
        {...props}
      />
    </Box>
  )
}
