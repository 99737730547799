import { Icon, Stack, chakra } from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { FormattedMessage } from "react-intl"
import { InfoIcon } from "../icons/icons.js"
import type { DynamicFormFieldWrapperProps } from "./DynamicFormFieldWrapper.js"

const DecisionDateFieldWrapper = ({
  suggestions,
  children,
  value,
  fieldMetadata,
  fieldApproval,
}: DynamicFormFieldWrapperProps): React.ReactNode => {
  const derivedSuggestion = suggestions?.find((suggestion) =>
    suggestion.field_sources.find((source) => source.type === "derived")
  )?.value as string | undefined
  const val = value as string | null | undefined
  if (
    !fieldMetadata?.verified &&
    !fieldApproval?.fieldIsApproved &&
    val &&
    derivedSuggestion &&
    Temporal.PlainDate.compare(Temporal.PlainDate.from(val), Temporal.PlainDate.from(derivedSuggestion)) > 0
  ) {
    return (
      <Stack>
        {children}
        <chakra.span color="warning.700">
          <Icon as={InfoIcon} />{" "}
          <FormattedMessage
            id="legalAgreement.decisionDate.warning"
            defaultMessage="This date is after the BRM suggested date. Please verify."
            description="Message displayed when the decision date exceeds the derived suggestion."
          />
        </chakra.span>
      </Stack>
    )
  }
  return children
}

export default DecisionDateFieldWrapper
