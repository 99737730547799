import type { CurrencyAmount } from "@brm/schema-types/types.js"
import { formatCurrency, formatWithCurrencyCode } from "@brm/util/currency/format.js"
import { Flex, Stack, Tooltip } from "@chakra-ui/react"
import { useIntl } from "react-intl"
import { FormattedCurrency } from "./FormattedCurrency.js"

export const FormattedMultiCurrency: React.FunctionComponent<{
  currencyAmount: CurrencyAmount
  amountsPerCurrency?: CurrencyAmount[]
}> = ({ currencyAmount, amountsPerCurrency }) => {
  const intl = useIntl()
  if (!amountsPerCurrency) {
    return <>{formatCurrency(currencyAmount, intl)}</>
  }

  return (
    <Tooltip
      label={
        amountsPerCurrency.length > 1 ? (
          <Stack spacing={1} alignItems="end">
            {amountsPerCurrency.map((currencyAmount) => (
              <Flex key={currencyAmount.currency_code}>{formatWithCurrencyCode(currencyAmount)}</Flex>
            ))}
          </Stack>
        ) : amountsPerCurrency.length === 1 && amountsPerCurrency[0]?.currency_code !== currencyAmount.currency_code ? (
          formatWithCurrencyCode(currencyAmount)
        ) : undefined
      }
      shouldWrapChildren
      isDisabled={amountsPerCurrency.length === 0}
    >
      <FormattedCurrency currencyAmount={amountsPerCurrency.length === 1 ? amountsPerCurrency[0]! : currencyAmount} />
    </Tooltip>
  )
}
