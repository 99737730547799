import { Flags } from "@brm/util/flags.js"
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Image,
  List,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
} from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { useFlags } from "launchdarkly-react-client-sdk"
import { useEffect, useMemo, useRef, useState, type FunctionComponent } from "react"
import useInfiniteScroll from "react-infinite-scroll-hook"
import { FormattedMessage, useIntl } from "react-intl"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import { isNotUndefined } from "typed-assert"
import emptyStateGif from "../../../assets/empty_state_clap_one_loop.gif"
import {
  useGetNotificationV1CountQuery,
  useGetNotificationV1Query,
  useGetNotificationV1TasksQuery,
  useGetUserV1WhoamiQuery,
  usePutNotificationV1BulkMutation,
  usePutNotificationV1ByIdMutation,
  type GetNotificationV1ApiArg,
  type Task,
} from "../../app/services/generated-api.js"
import Button from "../../components/Button/Button.js"
import { ButtonStyles } from "../../components/Button/types.js"
import {
  DeleteIcon,
  DotsHorizontalIcon,
  NotificationBoxIcon,
  ReadUnreadIcon,
  TrashIcon,
} from "../../components/icons/icons.js"
import { log } from "../../util/logger.js"
import NotificationItem from "./notification/NotificationItem.js"
import { getNotificationSrcUrl } from "./notification/helpers.js"
import TaskItem from "./task/TaskItem.js"

const INBOX_POLLING_INTERVAL = 10_000
const untilOffset = Temporal.Duration.from({ milliseconds: INBOX_POLLING_INTERVAL })
const limit = 100

export const Inbox: FunctionComponent = () => {
  const intl = useIntl()
  const { notificationId } = useParams()
  const { [Flags.TASKS_ENABLED_FLAG]: tasksEnabled } = useFlags()

  const notificationCountResult = useGetNotificationV1CountQuery()

  const [bulkUpdateNotifications] = usePutNotificationV1BulkMutation()

  const markAllAsRead = () => {
    void bulkUpdateNotifications({ notificationBulkActionBody: { action: "mark_all_read" } })
  }

  const markAllAsUnread = () => {
    void bulkUpdateNotifications({ notificationBulkActionBody: { action: "mark_all_unread" } })
  }

  const deleteRead = () => {
    void bulkUpdateNotifications({ notificationBulkActionBody: { action: "delete_read" } })
  }

  const deleteAll = () => {
    void bulkUpdateNotifications({ notificationBulkActionBody: { action: "delete_all" } })
  }

  const [isTaskSectionExpanded, setIsTaskSectionExpanded] = useState(false)

  const [initialInstant] = useState(() => Temporal.Now.instant())
  // Initially start with only one page, then append more params to load more pages.
  const [paginationParamsForEachPage, setPaginationParamsForEachPage] = useState<GetNotificationV1ApiArg[]>([
    // Query that gets new events until the next polling interval
    {
      since: initialInstant.toString(),
      until: initialInstant.add(untilOffset).toString(),
      limit,
    },
    // Gets first historical page
    { until: initialInstant.toString(), limit },
  ])

  // Also subscribe here to only the query for the last page, in addition to the <Notifications> component, to read the nextPageParams.
  const lastPageQuery = useGetNotificationV1Query({ ...paginationParamsForEachPage.at(-1), limit })
  const firstPageQuery = useGetNotificationV1Query({ ...paginationParamsForEachPage.at(0), limit })

  const tasksQuery = useGetNotificationV1TasksQuery()

  const scrollContainerRef = useRef<HTMLUListElement>(null)

  const [sentryRef, { rootRef: infiniteScrollRootRefCallback }] = useInfiniteScroll({
    loading: lastPageQuery.isFetching,
    hasNextPage: !!lastPageQuery.data?.nextPageParams,
    disabled: lastPageQuery.isError,
    onLoadMore: () => {
      setPaginationParamsForEachPage((params) =>
        lastPageQuery.data?.nextPageParams ? [...params, lastPageQuery.data.nextPageParams] : params
      )
    },
    rootMargin: "0px 0px 800px 0px",
  })
  useEffect(() => {
    infiniteScrollRootRefCallback(scrollContainerRef.current)
  }, [infiniteScrollRootRefCallback])

  // If the first page has a previousPageParams, we need to add a new page with previousPageParams.since and the until as the current time with the offset
  useEffect(() => {
    if (firstPageQuery.data?.previousPageParams?.since) {
      const newSince = firstPageQuery.data.previousPageParams.since
      const newUntil = Temporal.Now.instant().add(untilOffset).toString()

      // When we have a new page of events, we need to set the since and until values so that they fetch a consistent set of events.
      setPaginationParamsForEachPage((params) => [{ since: newSince, until: newUntil, limit }, ...params])
    }
  }, [firstPageQuery.data])

  useEffect(() => {
    // Poll for new events every INBOX_POLLING_INTERVAL
    const timeout = setTimeout(() => {
      if (
        firstPageQuery.isFetching ||
        !firstPageQuery.data ||
        (scrollContainerRef.current?.scrollTop ?? 0) > 50 ||
        document.visibilityState === "hidden"
      ) {
        return
      }
      const newestPage = paginationParamsForEachPage.at(0)
      isNotUndefined(newestPage?.until, "All pages should have an until value")

      const newSince = newestPage.until
      const newUntil = Temporal.Now.instant().add(untilOffset).toString()

      // When we have a new page of events, we need to set the since and until values so that they fetch a consistent set of events.
      setPaginationParamsForEachPage((params) => [{ since: newSince, until: newUntil, limit }, ...params])
    }, INBOX_POLLING_INTERVAL)
    return () => clearTimeout(timeout)
  }, [firstPageQuery.isFetching, firstPageQuery.data, paginationParamsForEachPage])

  // Mark selected notification as read
  const [updateNotification] = usePutNotificationV1ByIdMutation()
  useEffect(() => {
    if (notificationId) {
      updateNotification({
        id: notificationId,
        notificationUpdate: {
          read_at: Temporal.Now.instant().toString(),
        },
      }).catch((err) => {
        log.error("Failed to mark notification as read", err)
      })
    }
  }, [notificationId, updateNotification])

  if (!notificationCountResult.data) {
    return null
  }

  const tasks: Task[] | undefined = tasksQuery.data?.items

  const baseNumTasksToShow = 3

  return (
    <Flex height="100%" flexShrink={0}>
      <Flex flexDir="column" width="400px" borderRightWidth={1} flexShrink={0}>
        <Flex flexDir="column" borderBottomWidth={1} height="100%" flex="1 0 auto" overflowY="auto">
          {/* Task Section */}
          {tasksEnabled && (
            <Flex flexDirection="column" alignItems="flex-start" justifyContent="center">
              <Flex
                pt={2}
                pb={1}
                alignItems="center"
                justifyContent="space-between"
                position="sticky"
                top={0}
                bg="white"
                zIndex={1}
                width="100%"
                px={4}
              >
                <Text fontSize="lg" fontWeight="semibold">
                  <FormattedMessage
                    defaultMessage="Tasks"
                    description="Title for the inbox tasks"
                    id="inbox.tasks.title"
                  />
                </Text>
              </Flex>
              {tasks?.length === 0 ? (
                <Flex flexDirection="column" alignItems="center" justifyContent="center" px={4} width="100%">
                  <Image src={emptyStateGif} height="7rem" />
                  <Text fontWeight="semibold" size="sm" color="gray.600" textAlign="center">
                    <FormattedMessage
                      defaultMessage="Nice work! You‘ve completed all of your tasks."
                      description="Message shown when all tasks are completed"
                      id="inbox.tasks.allCompleted"
                    />
                  </Text>
                </Flex>
              ) : (
                <List width="100%">
                  {tasks?.map((task, index) => {
                    if (isTaskSectionExpanded || index < baseNumTasksToShow) {
                      return <TaskItem key={index} task={task} />
                    }
                    return null
                  })}
                  {tasks && tasks.length > baseNumTasksToShow && !isTaskSectionExpanded && (
                    <Flex justifyContent="flex-start" px="3.75rem" pt={4}>
                      <Button
                        buttonStyles={ButtonStyles.LinkBrand}
                        onClick={() => setIsTaskSectionExpanded(true)}
                        size="md"
                        label={intl.formatMessage(
                          {
                            defaultMessage: "View {numberOfTasks} more tasks",
                            description: "Button to show more tasks",
                            id: "inbox.tasks.viewAll",
                          },
                          { numberOfTasks: tasks.length - baseNumTasksToShow }
                        )}
                      />
                    </Flex>
                  )}
                </List>
              )}
            </Flex>
          )}
          {/* Notifications Section */}
          <Flex
            px={4}
            pt={2}
            pb={1}
            alignItems="center"
            justifyContent="space-between"
            position="sticky"
            top={0}
            bg="white"
            zIndex={1}
          >
            <Text fontSize="lg" fontWeight="semibold">
              <FormattedMessage
                defaultMessage="Notifications"
                description="Title for the inbox notifications"
                id="inbox.notifications.title"
              />
            </Text>
            <Menu>
              <MenuButton as={IconButton} variant="ghost" icon={<Icon as={DotsHorizontalIcon} />} />
              <MenuList>
                <MenuItem onClick={markAllAsRead}>
                  <Icon as={ReadUnreadIcon} />
                  <FormattedMessage
                    id="notifications.bulkActions.markAllAsRead"
                    description="menu item to mark all notifications as read"
                    defaultMessage="Mark all as read"
                  />
                </MenuItem>
                <MenuItem onClick={markAllAsUnread}>
                  <Icon as={NotificationBoxIcon} />
                  <FormattedMessage
                    id="notifications.bulkActions.markAllAsUnread"
                    description="Mark all as unread"
                    defaultMessage="Mark all as unread"
                  />
                </MenuItem>
                <MenuItem onClick={deleteRead}>
                  <Icon as={DeleteIcon} />
                  <FormattedMessage
                    id="notifications.bulkActions.deleteAllRead"
                    description="Delete all read"
                    defaultMessage="Delete all read"
                  />
                </MenuItem>
                <MenuItem onClick={deleteAll}>
                  <Icon as={TrashIcon} />
                  <FormattedMessage
                    id="notifications.bulkActions.deleteAll"
                    description="Delete all notifications"
                    defaultMessage="Delete all notifications"
                  />
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
          <List>
            {firstPageQuery.isLoading && (
              <Flex justifyContent="center">
                <Spinner size="md" />
              </Flex>
            )}
            {notificationCountResult.data.count === 0 && (
              <Box fontWeight="semibold" textAlign="center" color="gray.600" p={4}>
                <FormattedMessage
                  defaultMessage="You don‘t have any notifications."
                  description="Message shown when there are no notifications"
                  id="inbox.empty"
                />
              </Box>
            )}
            {paginationParamsForEachPage.map((params) => (
              <Notifications
                key={`${params.since}-${params.until}`}
                params={params}
                selectedNotificationId={notificationId}
              />
            ))}
            {lastPageQuery.isLoading && !firstPageQuery.isLoading && (
              <Flex justifyContent="center">
                <Spinner size="md" />
              </Flex>
            )}
            {/* This box acts as the trigger for the intersection observer */}
            <Box ref={sentryRef} />
          </List>
        </Flex>
      </Flex>
      <Flex flexGrow={1} flexShrink={1} minWidth={0}>
        <Outlet />
      </Flex>
    </Flex>
  )
}

const Notifications: FunctionComponent<{
  params: GetNotificationV1ApiArg
  selectedNotificationId: string | undefined
}> = ({ params, selectedNotificationId }) => {
  const notificationListResult = useGetNotificationV1Query(params)
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const navigate = useNavigate()

  const selectedNotificationIndex = useMemo(() => {
    return notificationListResult.data?.items.findIndex((notification) => notification.id === selectedNotificationId)
  }, [notificationListResult.data, selectedNotificationId])

  if (!whoami) {
    return null
  }

  const organizationId = whoami?.organization_id
  if (!organizationId) {
    return null
  }

  const onSelectNextNotification = () => {
    if (selectedNotificationIndex === undefined) {
      return
    }
    const nextIndex = selectedNotificationIndex + 1
    const nextNotification = notificationListResult.data?.items[nextIndex]
    if (nextNotification) {
      navigate(getNotificationSrcUrl(nextNotification, whoami.organization_id))
    }
  }

  const onSelectPreviousNotification = () => {
    if (selectedNotificationIndex === undefined) {
      return
    }
    const previousIndex = selectedNotificationIndex - 1
    const previousNotification = notificationListResult.data?.items[previousIndex]
    if (previousNotification) {
      navigate(getNotificationSrcUrl(previousNotification, whoami.organization_id))
    }
  }

  return notificationListResult.data?.items.map((notification) => (
    <NotificationItem
      organizationId={organizationId}
      key={notification.id}
      notification={notification}
      selectedNotificationId={selectedNotificationId}
      onSelectNextNotification={onSelectNextNotification}
      onSelectPreviousNotification={onSelectPreviousNotification}
    />
  ))
}
