import type {
  DocumentBackgroundExtractionResponse,
  DocumentExtractionLegalAgreementInput,
  DocumentWithExtraction,
  FieldMetadataWithSuggestions,
  FormFieldConfig,
  LegalAgreementFieldsMetadata,
  MoveDocumentCreateAction,
  MoveDocumentUpdateAction,
} from "@brm/schema-types/types.js"
import { COMPLETED_EXTRACTION_STATUSES } from "@brm/type-helpers/document.js"
import { unreachable } from "@brm/util/unreachable.js"
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  CircularProgress,
  Flex,
  HStack,
  Icon,
  List,
  Tag,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react"
import objectPath from "object-path"
import { useCallback, useEffect, useMemo, useState, type ReactNode } from "react"
import type { Control, ControllerRenderProps } from "react-hook-form"
import { Controller, useFieldArray, useWatch } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation } from "react-router-dom"
import { isPresent } from "ts-is-present"
import { usePostDocumentV1MoveToAgreementMutation, type DocumentMinimal } from "../../../app/services/generated-api.js"
import { CheckIcon, MoveDocumentIcon } from "../../../components/icons/icons.js"
import { Link } from "../../../components/Link.js"
import { LONG_TOAST_DURATION } from "../../../util/constant.js"
import { matchesLegalAgreementPath, pathForLegalAgreement } from "../../../util/json-schema.js"
import ExtractLegalDocumentUpload from "../ExtractLegalDocumentUpload.js"

export interface AssociatedDocumentsFormState {
  documents: DocumentWithExtraction[]
}

export function AssociatedDocuments({
  control,
  legalAgreementId,
  legalAgreementInput,
  onDocumentAdded,
  onLastDocumentRemoved,
  submitDocuments,
  fieldsMetadata,
  onVerify,
  fields,
}: {
  control: Control<AssociatedDocumentsFormState>
  legalAgreementId: string | undefined
  legalAgreementInput: DocumentExtractionLegalAgreementInput | undefined
  onDocumentAdded?: (extractionResult: DocumentBackgroundExtractionResponse) => void
  onLastDocumentRemoved?: () => Promise<void>
  /** Used to trigger form submission during the onDocumentChange method. Submits the form with the original values but updated documents. */
  submitDocuments: () => Promise<void>
  fieldsMetadata?: LegalAgreementFieldsMetadata
  onVerify?: () => void
  fields: FormFieldConfig[]
}): ReactNode {
  const intl = useIntl()
  const location = useLocation()
  const toast = useToast()
  const documentsField = useFieldArray({ control, name: "documents" })
  const [selectedDocument, setSelectedDocument] = useState<Pick<DocumentMinimal, "id"> | undefined>(undefined)
  const hashParams = useMemo(() => new URLSearchParams(location.hash.slice(1)), [location.hash])
  const uploadedDocuments = useWatch({ control, name: "documents" })
  const selectedDocumentId = hashParams.get("document") || uploadedDocuments[0]?.id
  const [moveDocument] = usePostDocumentV1MoveToAgreementMutation()
  const documentFieldMetadata = fieldsMetadata?.documents
  const [accordionIndex, setAccordionIndex] = useState(documentFieldMetadata?.verified ? 1 : 0)
  const fieldsMetadataList: FieldMetadataWithSuggestions[] = useMemo(
    () =>
      fields
        .map((field) => {
          const path = field.is_custom ? ["custom", field.field_name] : [field.field_name]
          return fieldsMetadata && objectPath.get(fieldsMetadata, path)
        })
        .filter(isPresent),
    [fields, fieldsMetadata]
  )

  useEffect(() => {
    if (!documentFieldMetadata?.verified) {
      setAccordionIndex(0)
    } else {
      setAccordionIndex(1)
    }
  }, [documentFieldMetadata?.verified])

  useEffect(() => {
    if (selectedDocumentId) {
      const document = uploadedDocuments?.find((document) => document.id === selectedDocumentId)
      setSelectedDocument(document)
    }
  }, [selectedDocumentId, uploadedDocuments])

  const isExtracting = useMemo(
    () =>
      uploadedDocuments.some(
        (d) => d.extraction_status && !COMPLETED_EXTRACTION_STATUSES.includes(d.extraction_status)
      ),
    [uploadedDocuments]
  )
  const getTitle = (count: number) => (
    <h2>
      <HStack fontWeight="medium" fontSize="md" display="flex" justifyContent="start" alignItems="center" gap={2}>
        <FormattedMessage
          defaultMessage="Documents"
          description="Header for the associated documents section in the legal agreement editor"
          id="legalAgreement.associatedDocuments.header"
        />
        <Tag size="sm">{count}</Tag>
        {isExtracting && (
          <Tooltip
            label={intl.formatMessage({
              id: "legalAgreement.associatedDocuments.extractingTooltip",
              defaultMessage: "Document extraction in progress",
              description: "Tooltip message indicating that document extraction is in progress",
            })}
            aria-label="Document extraction in progress"
          >
            <CircularProgress color="purple.500" isIndeterminate={true} size="1.2em" />
          </Tooltip>
        )}
      </HStack>
    </h2>
  )
  const renderField = useCallback(
    (field: ControllerRenderProps<AssociatedDocumentsFormState, "documents">) => (
      <List spacing={4} display="flex" flexDirection="column">
        <ExtractLegalDocumentUpload
          fieldsMetadataList={fieldsMetadataList}
          onMoveDocument={async (
            documentId: string,
            newAgreement: MoveDocumentCreateAction | MoveDocumentUpdateAction
          ) => {
            if (legalAgreementId) {
              try {
                // Will create a new agreement if new_agreement_id is empty
                const response = await moveDocument({
                  moveDocumentRequest: {
                    document_id: documentId,
                    old_agreement_id: legalAgreementId,
                    new_agreement: newAgreement,
                  },
                }).unwrap()
                if (field.value.length === 1) {
                  await onLastDocumentRemoved?.()
                }
                toast({
                  icon: <Icon boxSize={6} as={MoveDocumentIcon} />,
                  status: "success",
                  duration: LONG_TOAST_DURATION,
                  description: (
                    <Text isTruncated textOverflow="ellipsis" overflow="hidden">
                      <FormattedMessage
                        id="associatedRecords.moveToAgreement.success"
                        description="Toast shown when document is successfully moved to new agreement"
                        defaultMessage="Document successfully moved to {newAgreement}"
                        values={{
                          newAgreement: (
                            <Link
                              to={{
                                search: matchesLegalAgreementPath(location.pathname) ? location.search : undefined,
                                pathname: pathForLegalAgreement(response.id),
                              }}
                              state={location.state}
                              onClick={() => toast.closeAll()}
                              textDecoration="underline"
                              textUnderlineOffset="3px"
                            >
                              {response.display_name}
                            </Link>
                          ),
                        }}
                      />
                    </Text>
                  ),
                })
              } catch (err) {
                toast({
                  status: "error",
                  description: intl.formatMessage({
                    id: "associatedRecords.moveToAgreement.error",
                    description: "Toast shown when document is fails to be moved to new agreement",
                    defaultMessage: "Document failed to be moved",
                  }),
                })
              }
            }
          }}
          selectedDocument={selectedDocument}
          ref={field.ref}
          multiple={true}
          onDocumentChange={async (documents, type, document) => {
            switch (type) {
              case "remove": {
                documentsField.remove(
                  field.value.findIndex((fieldValueDocument) => fieldValueDocument.id === document.id)
                )
                await submitDocuments()

                if (documents.length === 0) {
                  await onLastDocumentRemoved?.()
                }
                break
              }
              case "add":
                documentsField.append(document)
                break

              default:
                unreachable(type)
            }
          }}
          getLinkDestination={(document) => {
            const documentHash = new URLSearchParams(hashParams)
            documentHash.set("document", document.id)
            return {
              search: matchesLegalAgreementPath(location.pathname) ? location.search : undefined,
              hash: `#${documentHash}`,
            }
          }}
          value={uploadedDocuments}
          onExtractionEnqueued={onDocumentAdded}
          legalAgreementId={legalAgreementId}
          legalAgreementInput={legalAgreementInput}
          fieldName="documents"
        />
      </List>
    ),
    [
      documentsField,
      fieldsMetadataList,
      hashParams,
      intl,
      legalAgreementId,
      legalAgreementInput,
      location.pathname,
      location.search,
      location.state,
      moveDocument,
      onDocumentAdded,
      onLastDocumentRemoved,
      selectedDocument,
      submitDocuments,
      toast,
      uploadedDocuments,
    ]
  )

  return (
    <Controller
      name="documents"
      control={control}
      render={({ field }) => {
        const unverified = !documentFieldMetadata?.verified && field.value.length > 0 && onVerify
        return (
          <Accordion
            allowToggle={!unverified}
            index={accordionIndex}
            onChange={(i: number | number[]) => {
              if (typeof i === "number") {
                setAccordionIndex(i)
              }
            }}
          >
            <AccordionItem border={0}>
              <AccordionButton
                flex={1}
                justifyContent="space-between"
                _hover={{ backgroundColor: "white", cursor: unverified && "default" }}
                paddingX={0}
                as={unverified ? Flex : undefined}
              >
                {getTitle(field.value.length)}
                {unverified ? (
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      onVerify()
                      setAccordionIndex(1)
                    }}
                    leftIcon={<Icon as={CheckIcon} color="success.500" />}
                  >
                    <FormattedMessage defaultMessage="Accept" description="Accept field" id="schemaForm.accept" />
                  </Button>
                ) : (
                  <AccordionIcon />
                )}
              </AccordionButton>
              <AccordionPanel w="full" padding={2}>
                {renderField(field)}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        )
      }}
    />
  )
}
