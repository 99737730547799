import {
  type CustomizableObjectType,
  type FieldCategory,
  type FieldConfig,
  type FieldConfigMinimal,
  type SimilarFieldQuery,
} from "@brm/schema-types/types.js"
import { FieldCategorySchema } from "@brm/schemas"
import { uniqBy } from "@brm/util/collections.js"
import { getTitle, isEnumArrayType, isEnumType, titleToFieldName } from "@brm/util/schema.js"
import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  ListItem,
  Text,
  Textarea,
  Tooltip,
  UnorderedList,
  chakra,
} from "@chakra-ui/react"
import type { JSONSchemaObject } from "@json-schema-tools/meta-schema"
import { Select } from "chakra-react-select"
import { forwardRef, useMemo } from "react"
import { Controller, useForm, useWatch } from "react-hook-form"
import { FormattedList, FormattedMessage, useIntl } from "react-intl"
import { useDebouncedCallback } from "use-debounce"
import { useLazyPostSchemaV1FieldsDuplicatesQuery } from "../../../app/services/generated-api.js"
import { HelpIcon } from "../../../components/icons/icons.js"
import { Link } from "../../../components/Link.js"
import Spinner from "../../../components/spinner.js"
import { initializeReactHookFromState } from "../../../util/form.js"
import { EnumOptionEditor } from "./EnumOptionEditor.js"
import FieldSchemaTypeSelect from "./FieldSchemaTypeSelect.js"
import type { CriterionFormState, JSONSchemaObjectOnly } from "./types.js"
import { DEFAULT_CRITERION_FORM_STATE, customizableObjectTypes, formatCustomizableObjectType } from "./util.js"

const categorizableObjectTypes = new Set<CustomizableObjectType>(["Tool", "Vendor"])

export const CriterionConfigForm = forwardRef<
  HTMLFormElement,
  {
    id?: string
    fieldConfig?: FieldConfig
    titleInputRef?: React.MutableRefObject<HTMLInputElement | null>
    listWorkflowDefs?: boolean
    onValidSubmit?: (config: FieldConfigMinimal) => void
    validateFieldName?: (value: string) => true | string
    defaultValues?: CriterionFormState
    showObjectPicker?: boolean // New prop
  }
>(function CriterionConfigForm(
  {
    fieldConfig,
    titleInputRef,
    defaultValues = { ...DEFAULT_CRITERION_FORM_STATE },
    listWorkflowDefs = true,
    onValidSubmit,
    validateFieldName,
    showObjectPicker = false, // Default value
    ...formProps
  },
  ref
) {
  const intl = useIntl()

  const [findDuplicates, duplicatesResult] = useLazyPostSchemaV1FieldsDuplicatesQuery()
  const duplicateFields = uniqBy(duplicatesResult.data ?? [], (field) => field.field_name)

  const debouncedFindDuplicates = useDebouncedCallback(findDuplicates, 500, { trailing: true })

  interface ObjectTypeOption {
    value: CustomizableObjectType
    label: string
  }
  const objectTypeOptions = useMemo(
    (): ObjectTypeOption[] =>
      customizableObjectTypes.map((type) => ({
        label: formatCustomizableObjectType(type, intl),
        value: type,
      })),
    [intl]
  )
  interface CategoryOption {
    value: FieldCategory | null
    label: string
  }
  const categoryOptions = useMemo((): CategoryOption[] => {
    const options: CategoryOption[] = [
      // You cannot remove the category from a standard tool field that has a category by default
      ...(!fieldConfig || fieldConfig.is_custom || !fieldConfig.category
        ? [
            {
              value: null,
              label: intl.formatMessage({
                defaultMessage: "None",
                description: "Category for uncategorized criteria",
                id: "settings.criteria.category.uncategorized",
              }),
            },
          ]
        : []),
      ...FieldCategorySchema.anyOf.map((category) => ({
        label: getTitle(category.const, category),
        value: category.const,
      })),
    ]

    // Sort the options alphabetically by label, keeping "None" at the top
    return options.sort((a, b) => {
      if (a.value === null) return -1
      if (b.value === null) return 1
      return a.label.localeCompare(b.label)
    })
  }, [fieldConfig, intl])

  const form = useForm<CriterionFormState>({
    defaultValues: (fieldConfig
      ? {
          dataType: fieldConfig.field_schema as JSONSchemaObjectOnly,
          title: getTitle(fieldConfig.field_name, fieldConfig.field_schema),
          description: (fieldConfig.field_schema as JSONSchemaObjectOnly)?.description ?? "",
          category: fieldConfig.category,
          object_type: fieldConfig.object_type as CustomizableObjectType,
          is_internal_only: fieldConfig.is_internal_only,
        }
      : defaultValues) satisfies CriterionFormState,
  })
  initializeReactHookFromState(form)

  const schema = useWatch({ name: "dataType", control: form.control })
  const schemaWithValue = { schema }

  // For new fields, check for duplicates as the user types
  if (!fieldConfig) {
    form.watch(async (formState) => {
      // Clone state before passing it to rtk-query because rtk-query will deeply freeze anything passed to it,
      // which then breaks form state changes.
      const similarFieldQuery: SimilarFieldQuery = structuredClone({
        object_type: formState.object_type,
        category: formState.category ?? undefined,
        field_schema: {
          ...formState.dataType,
          title: formState.title?.trim() || undefined,
          description: formState.description?.trim() || undefined,
          // Ensure we don't store a $id in the schema, which can mess with RefResolver
          $id: undefined,
        },
      })
      await debouncedFindDuplicates({ similarFieldQuery })
    })
  }

  const objectType = useWatch({ control: form.control, name: "object_type" })

  // Changing data types of existing fields is not currently allowed (it would require deleting all existing data of the field)
  const isDataTypeReadOnly = !!fieldConfig

  return (
    <chakra.form
      gap={3}
      display="flex"
      flexDir="column"
      noValidate
      ref={ref}
      {...formProps}
      onSubmit={form.handleSubmit((values) => {
        const isCustom = fieldConfig?.is_custom ?? true
        // Clone state for protection before passing it to the caller because rtk-query will deeply freeze
        // anything passed to it, which then breaks form state changes.
        const submittedValues: FieldConfigMinimal = structuredClone({
          object_type: values.object_type,
          field_name: fieldConfig?.field_name ?? titleToFieldName(values.title, intl),
          is_custom: isCustom,
          is_enabled: fieldConfig?.is_enabled ?? true,
          category: values.category,
          field_schema: isCustom
            ? {
                ...values.dataType,
                title: values.title.trim() || undefined,
                description: values.description.trim() || undefined,
                // Ensure we don't store a $id in the schema, which can mess with RefResolver
                $id: undefined,
              }
            : null,
          is_internal_only: values.is_internal_only,
          needs_reextraction: fieldConfig?.needs_reextraction ?? false,
        })
        onValidSubmit?.(submittedValues)
      })}
    >
      <Controller
        control={form.control}
        name="title"
        rules={{
          required: intl.formatMessage({
            defaultMessage: "Please provide a name for the criterion",
            description: "Error message for missing criterion name",
            id: "enumOptionEditor.error.required",
          }),
          validate: validateFieldName && ((title) => validateFieldName(titleToFieldName(title, intl))),
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isRequired isReadOnly={fieldConfig?.is_custom === false}>
            <FormLabel>
              <FormattedMessage
                id="settings.criteria.criterion.name.label"
                description="Label for the criterion name input"
                defaultMessage="Criteria name"
              />
            </FormLabel>
            <InputGroup>
              <Input
                {...field}
                ref={(el) => {
                  field.ref(el)
                  if (titleInputRef) {
                    titleInputRef.current = el
                  }
                }}
              />
              {duplicatesResult.isFetching && (
                <InputRightElement>
                  <Spinner />
                </InputRightElement>
              )}
            </InputGroup>
            {fieldState.error ? (
              <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>
            ) : duplicateFields.length ? (
              <FormHelperText role="alert">
                <FormattedMessage
                  defaultMessage="{fieldList} already {fieldCount, plural, one {exists} other {exist}} as {fieldCount, plural, one {a criterion} other {criteria}} in your BRM. Please review it to avoid duplicates."
                  description="Hint message for duplicate custom criterion"
                  id="settings.criteria.criterion.duplicate.custom"
                  values={{
                    fieldList: (
                      <FormattedList
                        value={duplicateFields.map((field) => (
                          <Link
                            key={field.field_name}
                            variant="highlighted"
                            to={{
                              pathname: `../${field.object_type === "LegalAgreement" ? "legal" : field.object_type.toLowerCase()}`,
                              hash: field.field_name,
                            }}
                            relative="path"
                          >
                            {getTitle(field.field_name, field.field_schema)}
                          </Link>
                        ))}
                      />
                    ),
                    fieldCount: duplicateFields.length,
                  }}
                />
              </FormHelperText>
            ) : null}
          </FormControl>
        )}
      />
      <Controller
        control={form.control}
        name="description"
        rules={{
          required: intl.formatMessage({
            defaultMessage: "Please provide a description for the criteria",
            description: "Error message for missing criterion name",
            id: "enumOptionEditor.error.required",
          }),
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isRequired isReadOnly={fieldConfig?.is_custom === false}>
            <FormLabel>
              <FormattedMessage
                id="settings.criteria.criterion.description.label"
                description="Label for the criterion description input"
                defaultMessage="Criteria description"
              />
            </FormLabel>
            <Textarea {...field} />
            {fieldState.error && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
          </FormControl>
        )}
      />
      <Controller
        control={form.control}
        name="dataType"
        rules={{
          required: intl.formatMessage({
            defaultMessage: "Please select the data type of the criteria",
            description: "Error message for missing criterion name",
            id: "enumOptionEditor.error.required",
          }),
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isRequired isReadOnly={isDataTypeReadOnly}>
            <FormLabel>
              <FormattedMessage
                id="settings.criteria.criterion.dataType.label"
                description="Label for the criterion data type input"
                defaultMessage="Criteria data type"
              />
            </FormLabel>
            <FieldSchemaTypeSelect
              value={field.value}
              onChange={field.onChange}
              ref={field.ref}
              isDisabled={field.disabled}
              isReadOnly={isDataTypeReadOnly}
            />
            {fieldState.error && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
          </FormControl>
        )}
      />
      {(isEnumType(schemaWithValue) || isEnumArrayType(schemaWithValue)) && (
        <EnumOptionEditor
          form={form}
          enumRootPath={schemaWithValue.schema.type === "array" ? "dataType.items" : "dataType"}
          isReadOnly={isDataTypeReadOnly}
          updateConstValues={!fieldConfig}
        />
      )}

      {showObjectPicker && (
        <Controller
          control={form.control}
          name="object_type"
          render={({ field, fieldState }) => (
            <FormControl
              isInvalid={fieldState.invalid}
              isRequired={!categoryOptions.some((o) => o.value === null)}
              isReadOnly={!!fieldConfig}
            >
              <FormLabel>
                <FormattedMessage
                  id="settings.criteria.criterion.objectType.label"
                  description="Label for the criterion object type input"
                  defaultMessage="Associated object"
                />{" "}
              </FormLabel>
              <Select<ObjectTypeOption, false, never>
                options={objectTypeOptions}
                value={objectTypeOptions.find((o) => o.value === field.value)}
                onChange={(option) => field.onChange(option?.value ?? null)}
                isDisabled={field.disabled}
                isSearchable={false}
                ref={(selectInstance) => field.ref(selectInstance?.inputRef)}
                menuPortalTarget={document.body}
                isReadOnly={!!fieldConfig}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: "var(--chakra-zIndices-popover)" }),
                }}
              />
              {fieldState.error && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
            </FormControl>
          )}
        />
      )}

      {categorizableObjectTypes.has(objectType) && (
        <Controller
          control={form.control}
          name="category"
          render={({ field, fieldState }) => (
            <FormControl
              isInvalid={fieldState.invalid}
              isRequired={!categoryOptions.some((o) => o.value === null)}
              isReadOnly={(fieldConfig?.field_schema as JSONSchemaObject)?.configurable === false}
            >
              <FormLabel>
                <FormattedMessage
                  id="settings.criteria.criterion.category.label"
                  description="Label for the criterion category input"
                  defaultMessage="Criteria category"
                />{" "}
                <Tooltip
                  label={
                    <Box padding={1}>
                      <FormattedMessage
                        id="settings.criteria.criterion.category.label"
                        description="Label for the criterion category input"
                        defaultMessage="The category determines"
                      />

                      <UnorderedList paddingLeft={1}>
                        <ListItem>
                          <FormattedMessage
                            id="settings.criteria.criterion.category.label.item1"
                            description="Entry for the field category tool tip"
                            defaultMessage="Where the field appears on a request"
                          />
                        </ListItem>
                        <ListItem>
                          <FormattedMessage
                            id="settings.criteria.criterion.category.label.item2"
                            description="Entry for the field category tool tip"
                            defaultMessage="Where the field appears in the tool or vendor detail page"
                          />
                        </ListItem>
                        <ListItem>
                          <FormattedMessage
                            id="settings.criteria.criterion.category.label.item3"
                            description="Entry for the field category tool tip"
                            defaultMessage="The permissions required to view or edit the field"
                          />
                        </ListItem>
                      </UnorderedList>

                      {fieldConfig && (
                        <FormattedMessage
                          defaultMessage="If the field is currently configured to be part of a request step, changing the category will also move it to the new corresponding step."
                          description="Helper text for changing a field’s category (edit only)"
                          id="settings.criteria.criterion.category.stepMoveHelperText"
                        />
                      )}
                    </Box>
                  }
                  shouldWrapChildren
                >
                  <Icon as={HelpIcon} lineHeight="inherit" boxSize="1lh" verticalAlign="bottom" />
                </Tooltip>
              </FormLabel>
              <Select<CategoryOption, false, never>
                options={categoryOptions}
                value={categoryOptions.find((o) => o.value === field.value)}
                onChange={(option) => field.onChange(option?.value ?? null)}
                isDisabled={field.disabled}
                isSearchable={false}
                ref={(selectInstance) => field.ref(selectInstance?.inputRef)}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: "var(--chakra-zIndices-popover)" }),
                }}
              />
              {fieldState.error && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
            </FormControl>
          )}
        />
      )}
      <FormControl>
        <FormLabel as={Text}>
          <FormattedMessage
            defaultMessage="Requests"
            description="Label for the field’s enabled on requests"
            id="settings.criteria.criterion.enabledOn.label"
          />
        </FormLabel>
        {fieldConfig && listWorkflowDefs && (
          <FormHelperText>
            {schema?.readOnly || schema?.requestConfigDisabled ? (
              <FormattedMessage
                defaultMessage="This field cannot be used in requests."
                id="settings.criteria.criterion.enabledOn.readOnly"
                description="Helper text for fields that cannot be used in request"
              />
            ) : (
              <>
                {fieldConfig.workflow_defs.length > 0 ? (
                  <UnorderedList mb={2}>
                    {fieldConfig.workflow_defs.map((def) => (
                      <ListItem key={def.id} mb={1}>
                        <Link variant="highlighted" to={`/requests/definitions/${def.id}`}>
                          {def.display_name}
                        </Link>
                      </ListItem>
                    ))}
                  </UnorderedList>
                ) : (
                  <FormattedMessage
                    defaultMessage="Not used yet."
                    description="Helper text for the field’s enabled on requests"
                    id="settings.criteria.criterion.enabledOn.notUsed"
                  />
                )}{" "}
                <FormattedMessage
                  defaultMessage="Add or remove this field to requests in your <link>requests configuration</link>"
                  description="Helper text for the field’s enabled on requests"
                  id="settings.criteria.criterion.enabledOn.helperText"
                  values={{
                    link: (content) => (
                      <Link variant="highlighted" to="/settings/requests">
                        {content}
                      </Link>
                    ),
                  }}
                />
              </>
            )}
          </FormHelperText>
        )}
      </FormControl>
      {!schema?.readOnly && !schema?.requestConfigDisabled && (
        <Controller
          control={form.control}
          name="is_internal_only"
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid}>
              <Flex alignItems="center" gap={2}>
                <Checkbox ref={field.ref} isChecked={!field.value} onChange={(e) => field.onChange(!e.target.checked)}>
                  <FormattedMessage
                    id="settings.criteria.criterion.isInternalOnly.label"
                    description="Label for the criterion internal only checkbox"
                    defaultMessage="Shared with sellers"
                  />
                </Checkbox>
                <Tooltip
                  label={intl.formatMessage({
                    defaultMessage: "If checked, this field will be visible to invited sellers in request forms",
                    description: "Tooltip for the criterion internal only checkbox",
                    id: "settings.criteria.criterion.isInternalOnly.tooltip",
                  })}
                  shouldWrapChildren
                >
                  <Icon as={HelpIcon} />
                </Tooltip>
              </Flex>
              {fieldState.error && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
            </FormControl>
          )}
        />
      )}
    </chakra.form>
  )
})
