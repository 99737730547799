import type { TimelineEvent, WorkflowRun } from "@brm/schema-types/types.js"
import { Card, CardBody, Divider, HStack, Stack, Text } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { isNotUndefined } from "typed-assert"
import { useGetUserV1WhoamiQuery } from "../../../app/services/generated-api.js"
import PersonCell from "../../../components/DataTable/CellRenderer/PersonCell.js"
import VendorCell from "../../../components/DataTable/CellRenderer/VendorCell.js"
import { EmptySymbol } from "../../../components/EmptySymbol.js"
import { Truncate } from "../../../components/Truncate.js"
import { LegalAgreementCard } from "../../legal/LegalAgreementCard.js"
import { CollapsibleTimelineWithInput } from "../../timeline/CollapsibleTimelineWithInput.js"
import type { GetLogoForOrganizationProps, GetOrganizationActorProps } from "./utils.js"

interface Props {
  workflowRun: WorkflowRun
  timelineEvents: Array<TimelineEvent>
}

export default function WorkflowRunContextSummary({
  workflowRun,
  timelineEvents,
  getLogoToShowByOrganizationId,
  getOrganizationActorWhenActorMissing,
}: Props & GetLogoForOrganizationProps & GetOrganizationActorProps) {
  const intl = useIntl()
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const [tool] = workflowRun.tools
  const vendor = workflowRun.vendor
  const activeLegalAgreement = workflowRun.active_legal_agreement

  isNotUndefined(tool, "WorkflowRunContextSummary: Unexpected workflow run without tool")

  return (
    <Stack gap={8}>
      <Card variant="outline">
        <CardBody>
          <Text as="span" fontSize="sm" fontWeight="medium">
            <FormattedMessage
              id="requests.context.tool_description.label"
              description="Label on context section of requests page that displays the description of the tool on the request"
              defaultMessage="Tool description"
            />
          </Text>
          <Truncate noOfLines={3}>
            {tool.description ? <Text marginTop={2}>{tool.description}</Text> : <EmptySymbol />}
          </Truncate>
          <Divider my={4} />
          <HStack>
            <Stack flex={1}>
              <Text fontWeight="medium">
                <FormattedMessage
                  id="requests.context.tool_owner.label"
                  description="Label on context section of requests page that displays the owner of the tool on the request"
                  defaultMessage="Tool owner"
                />
              </Text>
              {tool.owner ? <PersonCell person={tool.owner} size="sm" /> : <EmptySymbol />}
            </Stack>
            <Stack flex={1}>
              <Text fontWeight="medium">
                <FormattedMessage
                  id="requests.context.vendor.label"
                  description="Label on context section of requests page that displays the vendor of the tool on the request"
                  defaultMessage="Vendor"
                />
              </Text>
              {vendor ? <VendorCell size="sm" vendor={vendor} /> : <EmptySymbol />}
            </Stack>
          </HStack>
        </CardBody>
      </Card>
      {whoami && (
        <CollapsibleTimelineWithInput
          fieldTimelineProps={{
            timelineEvents,
          }}
          timelineCommentInputProps={{
            workflowRunId: workflowRun.id,
            showPrivacyControls: true,
            objectType: "WorkflowRun",
            objectId: workflowRun.id,
            label: intl.formatMessage({
              id: "workflowRun.context.commentInput.label",
              defaultMessage: "Context",
              description: "Label for the comment input in the workflow run context summary",
            }),
          }}
          getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
          getOrganizationActorWhenActorMissing={getOrganizationActorWhenActorMissing}
        />
      )}
      {activeLegalAgreement && <LegalAgreementCard legalAgreement={activeLegalAgreement} hideRenewalOption />}
    </Stack>
  )
}
