import type {
  DateString,
  FieldMetadata,
  FieldMetadataWithSuggestions,
  FieldSourceOutputProperties,
} from "@brm/schema-types/types.js"
import {
  isValidPlainDate,
  legacyDateToPlainDate,
  localeFirstDayOfWeek,
  plainDateToLegacyDate,
} from "@brm/util/date-time.js"
import {
  Box,
  Button,
  Circle,
  HStack,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  useDisclosure,
  useStyleConfig,
} from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { DateField, DateInput, DateSegment } from "react-aria-components"
// chakra-dayzed-datepicker requires date-fns library: https://github.com/aboveyunhai/chakra-dayzed-datepicker?tab=readme-ov-file#install-the-dependency
import { parseDate } from "@internationalized/date"
import { CalendarPanel, Month_Names_Short, Weekday_Names_Short } from "chakra-dayzed-datepicker"
import type { InputProps } from "chakra-react-select"
import type { Ref } from "react"
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useCustomCompareEffect } from "use-custom-compare"
import { getDefaultDateShortcuts, isNewOption, type DateShortcut } from "../../util/form.js"
import { FormattedDate } from "../FormattedDate.js"
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftDoubleIcon,
  ChevronLeftIcon,
  ChevronRightDoubleIcon,
  ChevronRightIcon,
} from "../icons/icons.js"
import OverflownText from "../OverflownText.js"
import FieldSource from "./FieldSource.js"
import OptionWithFieldSource from "./OptionWithFieldSource.js"
import type { SchemaFormFieldApproval, ValueWithSource } from "./types.js"

interface DatePickerInputProps {
  value: DateString | null
  onChange: (value: DateString | null, fieldSource?: FieldMetadata) => void
  isReadOnly?: boolean
  isInvalid?: boolean
  suggestions?: ValueWithSource<Temporal.PlainDate>[]
  isDisabled?: boolean
  ariaLabel?: InputProps["aria-label"]
  fieldMetadata?: FieldMetadataWithSuggestions
  fieldApproval?: SchemaFormFieldApproval
  /** Dates before this value will be disabled */
  minDate?: DateString
  /**
   * Date shortcuts to render in the calendar. If not provided, will show default shortcuts anchored off of today's date.
   */
  dateShortcuts?: DateShortcut[]
}

// Just a placeholder option to open up the calendar
interface CustomDateOption {
  isCustomOption: boolean
}

const isCustomOption = (
  option: ValueWithSource<Temporal.PlainDate | undefined> | CustomDateOption
): option is CustomDateOption => "isCustomOption" in option

const Calendar = ({
  onChange,
  value,
  minDate,
}: {
  onChange: (value: DateString | null, fieldSource?: FieldMetadata) => void
  // chakra-dayzed-datepicker library requires a Date object
  // eslint-disable-next-line no-restricted-globals
  value: Date | undefined
  /** Dates before this value will be disabled */
  // eslint-disable-next-line no-restricted-globals
  minDate?: Date
}) => {
  // eslint-disable-next-line no-restricted-globals
  const today = useMemo(() => new Date(), [])
  const intl = useIntl()
  const [offset, setOffset] = useState(0)

  useCustomCompareEffect(
    () => {
      if (value) {
        setOffset(0)
      }
    },
    [value],
    (prevValue, nextValue) => prevValue.toString() === nextValue.toString()
  )

  /** The first day of the week with 1 = Monday, 7 = Sunday. Default to Sunday if we don't have locale info. */
  const firstDayOfWeek = useMemo(() => localeFirstDayOfWeek(intl.locale) ?? 7, [intl.locale])
  return (
    <CalendarPanel
      dayzedHookProps={{
        showOutsideDays: true,
        onDateSelected: ({ date }) => {
          onChange(legacyDateToPlainDate(date).toString())
        },
        selected: value,
        date: value,
        minDate,
        offset,
        onOffsetChanged: setOffset,
      }}
      configs={{
        dateFormat: "YYYY-MM-DD",
        monthNames: Month_Names_Short,
        dayNames: Weekday_Names_Short,
        // chakra-dayzed-datepicker uses 0..6 with 0 = Sunday, 6 = Saturday
        // Intl.Locale's and Temporal uses 1..7 with 1 = Monday, 7 = Sunday
        firstDayOfWeek: (firstDayOfWeek % 7) as 0 | 1 | 2 | 3 | 4 | 5 | 6,
      }}
      propsConfigs={{
        dateHeadingProps: {
          fontSize: "md",
          // Fix width so arrows don't move around when switching months
          width: "70px",
        },
        calendarPanelProps: {
          contentProps: {
            border: 0,
            padding: 2,
          },
          dividerProps: {
            display: "none",
          },
          headerProps: {
            justifyContent: "space-between",
            width: "full",
          },
          wrapperProps: {
            width: "auto",
            justifyContent: "center",
          },
          bodyProps: {
            // Fix height so calendar doesn't jump around when switching months
            height: "230px",
          },
        },
        dateNavBtnProps: {
          variant: "ghost",
          color: "gray.500",
          size: "md",
          sx: {
            // Hide the default "<<" "<" ">" ">>" text content
            fontSize: 0,
            ".date-nav-icon": {
              fontSize: "initial",
            },
            // The props are shared between all four nav buttons, so we use CSS to show the correct icon for each.
            "&:nth-of-type(1) .date-nav-icon:not(.left-double)": {
              display: "none",
            },
            "&:nth-of-type(2) .date-nav-icon:not(.left)": {
              display: "none",
            },
            "&:nth-of-type(3) .date-nav-icon:not(.right)": {
              display: "none",
            },
            "&:nth-of-type(4) .date-nav-icon:not(.right-double)": {
              display: "none",
            },
          },
          leftIcon: (
            <>
              <Icon as={ChevronLeftDoubleIcon} className="date-nav-icon left-double" />
              <Icon as={ChevronLeftIcon} className="date-nav-icon left" />
              <Icon as={ChevronRightIcon} className="date-nav-icon right" />
              <Icon as={ChevronRightDoubleIcon} className="date-nav-icon right-double" />
            </>
          ),
          iconSpacing: 0,
        },
        weekdayLabelProps: {
          height: "30px",
          width: "30px",
          alignContent: "center",
          fontWeight: "medium",
        },
        dayOfMonthBtnProps: {
          defaultBtnProps: {
            borderRadius: "full",
            fontWeight: "normal",
            alignItems: "center",
            _hover: {
              backgroundColor: "gray.50",
            },
            height: "30px",
            width: "30px",
          },
          selectedBtnProps: {
            backgroundColor: "brand.700",
            color: "white",
            _hover: {
              backgroundColor: "brand.700",
            },
          },
          todayBtnProps: {
            flexDirection: "column",
            rightIcon: (
              <Circle
                bgColor={today.getDate() === value?.getDate() ? "white" : "brand.700"}
                style={{
                  width: "4px",
                  height: "4px",
                  position: "absolute",
                  left: "Calc(50% - 2.5px)",
                  bottom: "4px",
                }}
              />
            ),
          },
        },
      }}
    />
  )
}

const Option = ({
  option,
  onClick,
  isSelected,
  fieldMetadata,
  fieldApproval,
}: {
  option: ValueWithSource<Temporal.PlainDate | undefined> | CustomDateOption | null
  onClick?: () => void
  isSelected: boolean
  fieldMetadata?: FieldMetadata
  fieldApproval?: SchemaFormFieldApproval
}) => {
  let contents
  if (option === null) {
    contents = null
  } else if (isCustomOption(option)) {
    contents = (
      <Text flex={1}>
        <FormattedMessage
          defaultMessage="Custom Date"
          description="The label for a custom date option in the date picker"
          id="datePicker.option.customDate"
        />
      </Text>
    )
  } else {
    const fieldSources = option?.field_sources as FieldSourceOutputProperties[] | undefined
    if (!option.value) {
      return null
    }
    contents = (
      <>
        <OptionWithFieldSource fieldSources={fieldSources}>
          <FormattedDate value={option.value.toString()} month="numeric" />
        </OptionWithFieldSource>
        {isSelected && <Icon as={CheckIcon} boxSize={3} />}
      </>
    )
  }
  const newOption =
    option !== null && !isCustomOption(option)
      ? isNewOption(option.field_sources, fieldMetadata, fieldApproval)
      : undefined
  return (
    <Button
      backgroundColor="white"
      _hover={{
        backgroundColor: "gray.50",
      }}
      _active={{
        backgroundColor: "gray.200",
      }}
      _focusVisible={{
        boxShadow: "none",
        backgroundColor: "gray.100",
      }}
      textAlign="left"
      fontWeight="regular"
      display="flex"
      onClick={onClick}
      role="option"
      alignItems="center"
      borderRadius={0}
      {...(newOption?.isNew && { backgroundColor: `${newOption.colorScheme}.50` })}
    >
      {contents}
    </Button>
  )
}

/**
 * A date input with accompanying popover. The popover can either show a calendar for selecting any date, or show a list of suggestions as a dropdown menu.
 */
export const DatePickerInput = forwardRef(function DatePickerInput(
  props: DatePickerInputProps,
  ref: Ref<HTMLInputElement | null>
) {
  const {
    onChange,
    value,
    isReadOnly,
    isInvalid,
    isDisabled,
    suggestions,
    ariaLabel,
    fieldMetadata,
    fieldApproval,
    minDate,
    dateShortcuts,
  } = props
  const intl = useIntl()
  // Controls whether or the popover is open
  const { isOpen, onOpen, onClose } = useDisclosure()
  // Whether or not the custom date option is toggled. If this is true, the popover will show the calendar view regardless of whether there are suggestions.
  const [customDateOptionToggled, setCustomDateOptionToggled] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const optionsRef = useRef<HTMLDivElement>(null)
  useImperativeHandle(ref, () => inputRef.current)
  const options = useMemo(
    () => [null, ...(suggestions || []), { isCustomOption: true } satisfies CustomDateOption],
    [suggestions]
  )
  const date = value ? Temporal.PlainDate.from(value) : undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputStyleConfig = useStyleConfig("Input") as any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const menuStyleConfig = useStyleConfig("Menu") as any

  // Whether or not the calendar should be rendered. If the custom date option is toggled, or there are no suggestions, the calendar will be rendered.
  const shouldRenderCalendar = customDateOptionToggled || !suggestions || suggestions.length === 0
  // eslint-disable-next-line no-restricted-globals
  const legacyMinDate = minDate ? new Date(minDate) : undefined
  const closeAll = useCallback(() => {
    onClose()
    setCustomDateOptionToggled(false)
    inputRef.current?.blur()
  }, [onClose, setCustomDateOptionToggled])

  const shortcuts = useMemo(() => dateShortcuts ?? getDefaultDateShortcuts(intl), [dateShortcuts, intl])
  const today = Temporal.Now.plainDateISO().toString()

  const suggestedShortcutList = useMemo(() => {
    return suggestions?.map((suggestion, i) => (
      <Button
        isActive={value === suggestion.value.toString()}
        key={`suggested-shortcuts-${i}`}
        justifyContent="start"
        fontSize="sm"
        fontWeight="medium"
        variant="ghost"
        onClick={() => onChange(suggestion.value.toString(), suggestion.field_sources?.[0])}
        gap={1}
        width="full"
        marginBottom={1}
      >
        {suggestion.value.toLocaleString(intl.locale)}
        {suggestion?.field_sources?.[0] && <FieldSource fieldMetadata={suggestion.field_sources[0]} boxSize={3} />}
      </Button>
    ))
  }, [suggestions, value, intl.locale, onChange])

  // Today not in suggested shortcuts
  const showTodayOption = !suggestions || !suggestions.some((suggestion) => suggestion.value.toString() === today)

  const shortcutList = useMemo(() => {
    return (
      shortcuts
        // Remove any duplicate shortcuts
        .filter(
          (shortcut) =>
            !suggestions || !suggestions.find((suggestion) => suggestion.value.toString() === shortcut.date.toString())
        )
        .map((shortcut, i) => (
          <Button
            isActive={value === shortcut.date}
            key={`shortcuts-${i}`}
            justifyContent="start"
            fontSize="sm"
            fontWeight="medium"
            variant="ghost"
            onClick={() => onChange(shortcut.date)}
            width="full"
            marginBottom={1}
          >
            <OverflownText>{shortcut.displayName}</OverflownText>
          </Button>
        ))
    )
  }, [shortcuts, suggestions, value, onChange])

  return (
    <Popover onClose={closeAll} isOpen={!isReadOnly && isOpen} closeOnBlur={true} matchWidth={true} autoFocus={false}>
      <PopoverTrigger>
        <Button
          isDisabled={isDisabled}
          onClick={onOpen}
          isActive={!isReadOnly}
          sx={{
            ...inputStyleConfig.field,
            justifyContent: "start",
            cursor: "auto",
            fontWeight: "regular",
            background: 0,
            _active: {
              background: 0,
              ...(isInvalid && {
                ...inputStyleConfig.field._invalid,
              }),
            },
            _hover: { borderColor: 0 },
            ...(isOpen && inputStyleConfig.field._focus),
            ...(isReadOnly && inputStyleConfig.field._readOnly),
            ...(isInvalid && {
              ...inputStyleConfig.field._invalid,
              boxShadow: "0 0 0 3px var(--chakra-colors-error-100)",
            }),
          }}
          onBlur={(e) => {
            if (!optionsRef.current?.contains(e.relatedTarget as Node)) {
              closeAll()
            }
          }}
        >
          <DateField
            isReadOnly={isReadOnly}
            value={value ? parseDate(value?.toString()) : null}
            aria-label={ariaLabel}
            shouldForceLeadingZeros={true}
            onChange={(v) => {
              const newDate = v?.toString()
              if (newDate && isValidPlainDate(newDate)) {
                const matchingSuggestion = suggestions?.find((suggestion) => suggestion.value.toString() === newDate)
                onChange?.(newDate, matchingSuggestion?.field_sources?.[0])
              }
            }}
            isInvalid={isInvalid}
            ref={inputRef}
            onFocus={onOpen}
            onBlur={(e) => {
              if (!optionsRef.current?.contains(e.relatedTarget as Node)) {
                closeAll()
              }
            }}
          >
            <DateInput style={{ display: "flex" }}>{(segment) => <DateSegment segment={segment} />}</DateInput>
          </DateField>
          <Icon
            as={ChevronDownIcon}
            height="20px"
            width="20px"
            marginRight={2}
            marginLeft={1}
            position="absolute"
            right={0}
          />
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          width="full"
          sx={{ ...menuStyleConfig.list, borderRadius: "lg" }}
          ref={optionsRef}
          maxW={shouldRenderCalendar ? "500px" : undefined}
        >
          <PopoverBody padding={0}>
            {shouldRenderCalendar ? (
              <HStack minW={0} alignItems="start" gap={0} height="100%">
                {/* Height of calendar is fixed */}
                <Box padding={2} paddingBottom={0} width="full" overflowY="auto" height="335px">
                  {suggestedShortcutList}
                  {showTodayOption && (
                    <Button
                      isActive={value === today}
                      justifyContent="start"
                      fontSize="sm"
                      fontWeight="medium"
                      variant="ghost"
                      onClick={() => onChange(today)}
                      width="full"
                      marginBottom={1}
                    >
                      <FormattedMessage
                        id="calendar.today"
                        defaultMessage="Today"
                        description="Button on calendar input to set the value to today"
                      />
                    </Button>
                  )}
                  {shortcutList}
                </Box>

                <Stack borderColor="gray.200" borderLeftWidth={1} gap={0}>
                  <Calendar
                    value={date && plainDateToLegacyDate(date)}
                    onChange={(v) => {
                      const matchingSuggestion = suggestions?.find((suggestion) => suggestion.value.toString() === v)
                      if (matchingSuggestion) {
                        onChange(v, matchingSuggestion.field_sources?.[0])
                      } else {
                        onChange(v)
                      }
                      onClose()
                      setCustomDateOptionToggled(false)
                    }}
                    minDate={legacyMinDate}
                  />
                  <PopoverFooter paddingX={2}>
                    <HStack justifyContent="space-between">
                      <Button
                        variant="ghost"
                        onClick={() => {
                          onClose()
                          setCustomDateOptionToggled(false)
                          onChange(null)
                        }}
                        color="gray.500"
                      >
                        <FormattedMessage
                          id="calendar.clear"
                          defaultMessage="Clear"
                          description="Clear button on calendar input"
                        />
                      </Button>
                      <Button
                        colorScheme="brand"
                        onClick={() => {
                          onClose()
                          setCustomDateOptionToggled(false)
                        }}
                      >
                        <FormattedMessage
                          id="calendar.close"
                          defaultMessage="Close"
                          description="Close button on calendar input"
                        />
                      </Button>
                    </HStack>
                  </PopoverFooter>
                </Stack>
              </HStack>
            ) : (
              <Stack gap={0}>
                {options.map((option, i) => (
                  <Option
                    fieldApproval={fieldApproval}
                    fieldMetadata={fieldMetadata}
                    option={option}
                    key={i}
                    isSelected={
                      option === null
                        ? value === null
                        : !isCustomOption(option) && option?.value && value === option.value.toString()
                    }
                    onClick={() => {
                      if (option === null) {
                        onChange(null)
                        onClose()
                        setCustomDateOptionToggled(false)
                      } else if (!isCustomOption(option)) {
                        onChange(option.value.toString(), option.field_sources?.[0])
                        onClose()
                        setCustomDateOptionToggled(false)
                      } else {
                        setCustomDateOptionToggled(true)
                      }
                    }}
                  />
                ))}
              </Stack>
            )}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
})
