import type { FieldMetadataWithSuggestions, FieldSourceInputProperties } from "@brm/schema-types/types.js"
import { Input, type InputProps } from "@chakra-ui/react"
import { Select } from "chakra-react-select"
import type { Ref } from "react"
import { forwardRef, useImperativeHandle, useRef } from "react"
import { isNewOption } from "../../util/form.js"
import OptionWithFieldSource from "./OptionWithFieldSource.js"
import type { SchemaFormFieldApproval, ValueWithSource } from "./types.js"

export const StringInput = forwardRef(function StringInput(
  props: Omit<InputProps, "onChange" | "value"> & {
    suggestions?: ValueWithSource<string>[]
    onChange?: (value: string, fieldSource?: FieldSourceInputProperties) => void
    value: string
    fieldMetadata: FieldMetadataWithSuggestions
    fieldApproval?: SchemaFormFieldApproval
  },
  ref: Ref<HTMLInputElement | null>
) {
  const { suggestions, fieldMetadata, fieldApproval, ...rest } = props
  const inputRef = useRef<HTMLInputElement | null>(null)
  useImperativeHandle(ref, () => inputRef.current)

  if (suggestions && suggestions.length > 0) {
    return (
      <Select<ValueWithSource<string>>
        chakraStyles={{
          option: (provided, { data }) => {
            const { isNew, colorScheme } = isNewOption(data.field_sources, fieldMetadata, fieldApproval)
            return {
              ...provided,
              ...(isNew && { backgroundColor: `${colorScheme}.50` }),
            }
          },
        }}
        onChange={(option) => {
          if (option?.value) {
            props.onChange?.(option?.value, option?.field_sources?.[0])
          }
        }}
        value={{
          value: props.value,
        }}
        onInputChange={(value) => {
          if (value) {
            const matchingSuggestion = suggestions?.find(
              (suggestion) => suggestion.value.toLowerCase() === value.trim().toLowerCase()
            )
            props.onChange?.(value.trim(), matchingSuggestion?.field_sources?.[0])
          }
        }}
        options={suggestions}
        getOptionValue={({ value }) => value}
        getOptionLabel={({ value }) => value}
        formatOptionLabel={({ value, field_sources }, { context }) => {
          return context === "menu" ? (
            <OptionWithFieldSource fieldSources={field_sources}>{value}</OptionWithFieldSource>
          ) : (
            value
          )
        }}
        noOptionsMessage={() => null}
        menuPortalTarget={document.body}
        isSearchable={true}
        openMenuOnFocus={true}
        ref={(select) => {
          inputRef.current = select?.inputRef ?? null
        }}
      />
    )
  }
  return <Input {...rest} ref={inputRef} onChange={(e) => props.onChange?.(e.target.value)} />
})
