import type { ToolDetails, ToolOptionWithVendor, WorkflowKind } from "@brm/schema-types/types.js"
import {
  Alert,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react"
import { Controller, type Control, type UseFormSetValue } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import BasicOrganizationEntityPicker from "../../../../components/Form/BasicOrganizationEntityPicker.js"
import { ToolOptionPickerCreatable } from "../../../../components/Form/ToolOptionPicker.js"
import { VendorOptionPicker } from "../../../../components/Form/VendorOptionPicker.js"
import { AlertIcon, NextIcon } from "../../../../components/icons/icons.js"
import { Link } from "../../../../components/Link.js"
import { getDisplayUrl } from "../../../../util/url.js"
import { validateURL } from "../../../../util/validation.js"
import { displayWorkflowKind } from "../../util.js"
import type { StartWorkflowFormState, WorkflowToolSectionFormState } from "./util.js"
import { generateWorkflowDisplayName } from "./util.js"

export default function WorkflowToolSection({
  control,
  selectedToolWithDetails,
  selectedToolOption,
  isNewTool,
  newToolDisplayName,
  workflowKind,
  setValue,
  isPreselectedTool,
}: {
  selectedToolWithDetails: ToolDetails | undefined
  selectedToolOption: ToolOptionWithVendor | null
  isNewTool: boolean | null
  newToolDisplayName: string | null
  control: Control<WorkflowToolSectionFormState>
  workflowKind: WorkflowKind | null
  setValue: UseFormSetValue<StartWorkflowFormState>
  isPreselectedTool: boolean
}) {
  const intl = useIntl()

  const { display_name, vendor, description, website, tool_listing_id } =
    selectedToolWithDetails ?? selectedToolOption ?? {}

  // Search for any workflow run of the same kind that is in progress
  const existingWorkflow = selectedToolWithDetails?.workflow_runs.find(
    (wfr) => wfr.kind === workflowKind && wfr.status === "in_progress"
  )
  return (
    <Box>
      <Text>
        <FormattedMessage
          id="requests.start.modal.tool.header"
          description="Header for the start workflow modal tool selection section"
          defaultMessage="1. Search for a tool"
        />
      </Text>
      <Controller
        name="tool"
        control={control}
        rules={{
          required: true,
          validate: () => !existingWorkflow,
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isRequired marginTop={3} isReadOnly={isPreselectedTool}>
            <FormLabel>
              <FormattedMessage
                defaultMessage="Tool name"
                description="The label for the tool field in the start workflow modal form"
                id="requests.start.modal.tool.label"
              />
            </FormLabel>
            <ToolOptionPickerCreatable
              autoFocus
              allowCreate={true}
              value={field.value && { value: field.value }}
              onChange={(tool) => {
                field.onChange(tool?.value)
                if (tool?.value?.display_name && workflowKind) {
                  setValue("is_new_tool", null)
                  setValue("new_tool_display_name", null)
                  setValue("display_name", generateWorkflowDisplayName(tool.value.display_name, workflowKind, intl))
                }
                // handle the creation of a new tool
                if (tool?.value?.tool_listing_id === "new" && workflowKind) {
                  setValue("is_new_tool", true)
                  setValue("new_tool_display_name", tool.value?.display_name)
                }
              }}
              // This tool picker is rendered in a modal, so we shouldn't make the portal target document body
              menuPortalTarget={undefined}
            />
            {fieldState.error?.message && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
          </FormControl>
        )}
      />
      {existingWorkflow && workflowKind && (
        <Alert status="error" marginTop={2} gap={2}>
          <Icon as={AlertIcon} color="error" />
          <Text>
            <FormattedMessage
              id="requests.start.tool.already_exists"
              description="Error message shown if attempting to start a request on a tool that already has an active request"
              defaultMessage="There is already an active {workflowKind} request for this tool: {link}"
              values={{
                workflowKind: displayWorkflowKind(workflowKind),
                link: (
                  <Link to={`/requests/${existingWorkflow.id}`} color="blue.500">
                    {existingWorkflow.display_name}
                  </Link>
                ),
              }}
            />
          </Text>
        </Alert>
      )}
      {isNewTool ? (
        <>
          <Text marginTop={3}>
            <FormattedMessage
              id="requests.start.modal.tool.help_message"
              description="Header for the start workflow modal tool selection section"
              defaultMessage="{name} will be added as a tool in your organization"
              values={{ name: newToolDisplayName }}
            />
          </Text>
          <Controller
            name="new_tool_url"
            control={control}
            rules={{
              required: true,
              validate: validateURL(intl),
            }}
            render={(formState) => {
              const { field, fieldState } = formState

              return (
                <FormControl isInvalid={fieldState.invalid} isRequired marginTop={3}>
                  <FormLabel>
                    <FormattedMessage
                      defaultMessage="Tool URL"
                      description="The label for the vendor field in the start workflow modal form"
                      id="requests.start.modal.vendor.label"
                    />
                  </FormLabel>

                  <Input
                    autoComplete="off"
                    value={field?.value ?? ""}
                    placeholder={intl.formatMessage({
                      id: "requests.start.modal.tool.create.url.placeholder",
                      description: "Placeholder for the tool URL input field",
                      defaultMessage: "Please provide the URL of the tool you are adding...",
                    })}
                    onChange={field.onChange}
                  />

                  {fieldState.error?.message && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
                </FormControl>
              )
            }}
          />
        </>
      ) : null}

      {selectedToolOption && !isNewTool ? (
        <Controller
          name="tool_owner"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid} isRequired marginTop={3}>
              <FormLabel>
                <FormattedMessage
                  defaultMessage="Tool owner"
                  description="The label for the tool owner field in the start workflow modal form"
                  id="requests.start.modal.tool.owner.label"
                />
              </FormLabel>
              <BasicOrganizationEntityPicker
                // This is an uncontrolled component so if the owner changes we force re-render to make sure it picks up the new "initialValue"
                key={field.value?.id}
                includedEntities={["person"]}
                initialSelected={field.value}
                onChangePerson={(person) => field.onChange(person)}
                isRequired
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: "var(--chakra-zIndices-modal)" }),
                }}
              />
            </FormControl>
          )}
        />
      ) : null}

      {vendor && (
        <FormControl marginTop={3} isReadOnly>
          <FormLabel>
            <FormattedMessage
              defaultMessage="Vendor"
              description="The label for the vendor field in the start workflow modal form"
              id="requests.start.modal.vendor.label"
            />
          </FormLabel>
          <VendorOptionPicker value={vendor} isReadOnly />
        </FormControl>
      )}
      {tool_listing_id && tool_listing_id !== "new" && (
        <FormControl marginTop={3} isReadOnly>
          <FormLabel>
            <FormattedMessage
              defaultMessage="Tool description"
              description="The label for the tool description field in the start workflow modal form"
              id="requests.start.modal.tool.description.label"
            />
          </FormLabel>
          <Textarea
            rows={10}
            value={description ?? ""}
            placeholder={intl.formatMessage(
              {
                id: "requests.start.modal.tool.description.placeholder",
                defaultMessage: "No description found for {tool}",
                description: "Placeholder shown when there is no description on a displayed tool",
              },
              { tool: display_name }
            )}
            isReadOnly
          />
        </FormControl>
      )}
      {website ? (
        <Link to={website} target="_blank" display="inline-block" color="blue.500">
          <HStack marginTop={2} gap={1}>
            <FormattedMessage
              id="requests.start.modal.tool.website"
              description="Link text to click which navigates user to the website of a tool"
              defaultMessage="Learn more about {website}"
              values={{ website: getDisplayUrl(new URL(website)) }}
            />

            <Icon as={NextIcon} />
          </HStack>
        </Link>
      ) : null}
    </Box>
  )
}
