import { Box, Button, Collapse, Flex, Grid, Icon, useDisclosure } from "@chakra-ui/react"
import { useCallback, type FC } from "react"
import { FormattedMessage } from "react-intl"
import { ChevronDownIcon, ChevronUpIcon } from "../../components/icons/icons.js"
import FieldTimeline, { type FieldTimelineProps } from "../../components/Timeline/FieldTimeline.js"
import { TimelineCommentInput, type TimelineCommentInputProps } from "../../components/Timeline/TimelineCommentInput.js"
import type { GetLogoForOrganizationProps, GetOrganizationActorProps } from "../workflows/run/utils.js"

interface CollapsibleTimelineWithInputProps {
  fieldTimelineProps: FieldTimelineProps
  timelineCommentInputProps: TimelineCommentInputProps
  commentCount?: number
  /** Callback with side effects when the timeline is opened */
  onOpen?: () => void
  /** Callback with side effects when the timeline is closed */
  onClose?: () => void
  defaultIsOpen?: boolean
}

/**
 * A collapsible comment section that displays a timeline of comments and a comment input as well as a collapse/expand button to show/hide the timeline.
 */
export const CollapsibleTimelineWithInput: FC<
  CollapsibleTimelineWithInputProps & GetLogoForOrganizationProps & GetOrganizationActorProps
> = ({
  timelineCommentInputProps,
  fieldTimelineProps,
  onOpen,
  onClose,
  getLogoToShowByOrganizationId,
  getOrganizationActorWhenActorMissing,
  defaultIsOpen,
}) => {
  const timelineDisclosure = useDisclosure({ defaultIsOpen: defaultIsOpen ?? true })
  const timelineEventsExist = fieldTimelineProps.timelineEvents.length > 0
  const toggleTimeline = useCallback(() => {
    if (timelineDisclosure.isOpen) {
      timelineDisclosure.onClose()
      onClose?.()
    } else {
      timelineDisclosure.onOpen()
      onOpen?.()
    }
  }, [timelineDisclosure, onOpen, onClose])

  return (
    <Box>
      {timelineEventsExist ? (
        <>
          <Button
            display="flex"
            alignItems="center"
            variant="link"
            justifyContent="center"
            onClick={toggleTimeline}
            colorScheme="brand"
            gap={2}
          >
            <Flex boxSize={6} alignItems="center" justifyContent="center">
              <Icon as={timelineDisclosure.isOpen ? ChevronUpIcon : ChevronDownIcon} />
            </Flex>
            {timelineDisclosure.isOpen ? (
              <FormattedMessage
                defaultMessage="Hide activity"
                description="Button ARIA label to hide comments on a step"
                id="workflowRun.form.step.timeline.hide.aria.label"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Show activity"
                description="Button ARIA label to show comments on a step"
                id="workflowRun.form.step.timeline.show.aria.label"
              />
            )}
          </Button>
          <Collapse in={timelineDisclosure.isOpen} unmountOnExit>
            <FieldTimeline
              {...fieldTimelineProps}
              getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
              getOrganizationActorWhenActorMissing={getOrganizationActorWhenActorMissing}
            />

            <Grid templateColumns="[avatar] auto [input] 1fr" gap={2} mt={2}>
              {/* Small hack because we want the show/hide button content to be aligned with the timeline input avatar and body, but wrapping all of the content in the Collapse messes with the Grid rendering */}
              {timelineDisclosure.isOpen && (
                <TimelineCommentInput
                  {...timelineCommentInputProps}
                  getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
                />
              )}
            </Grid>
          </Collapse>
        </>
      ) : (
        <Grid templateColumns="[avatar] auto [input] 1fr" gap={2} mt={2}>
          <TimelineCommentInput
            {...timelineCommentInputProps}
            onOpen={() => {
              timelineDisclosure.onOpen()
              onOpen?.()
            }}
            getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
          />
        </Grid>
      )}
    </Box>
  )
}
