import type {
  PersonVariablePicker,
  UserPicker,
  VariableType,
  WorkflowDefUserApproverInput,
  WorkflowDefVariableApproverInput,
  WorkflowStepStandardType,
} from "@brm/schema-types/types.js"
import { uniqBy } from "@brm/util/collections.js"
import { displayPersonName } from "@brm/util/names.js"
import { Button, Divider, Flex, FormControl, Heading, HStack, Icon, IconButton, Stack, Tooltip } from "@chakra-ui/react"
import { useRef, useState } from "react"
import { Controller, useFieldArray, useWatch, type Control } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import OrganizationEntityPicker from "../../../components/Form/OrganizationEntityPicker.js"
import { HelpIcon, PlusIcon, TrashIcon } from "../../../components/icons/icons.js"
import { getMinimalRoleForPermission, getPermissionRequiredToEditStep } from "../run/utils.js"
import ConditionalPrompt from "./ConditionalPrompt.js"
import ConditionalToggle from "./ConditionalToggle.js"
import { getPrevStepFields, getUniqueFormFieldKey, type ConfigureWorkflowStepsFormState } from "./utils.js"

export default function ApprovalStepDefinition({
  approvalStep,
  approvalStepIdx,
  stepIndex,
  control,
  stepType,
  approverVariableTypes,
}: {
  approvalStep: ConfigureWorkflowStepsFormState["steps"][number]["approval_steps"][number]
  approvalStepIdx: number
  stepIndex: number
  control: Control<ConfigureWorkflowStepsFormState>
  stepType: WorkflowStepStandardType
  approverVariableTypes?: VariableType[]
}) {
  const intl = useIntl()

  const steps = useWatch({ control, name: "steps" })
  const currentStep = steps[stepIndex]

  const [conditionalToggle, setConditionalToggle] = useState<null | "conditional">(
    approvalStep.condition ? "conditional" : null
  )

  const conditionalInputRef = useRef<{ focus: () => void }>(null)

  const approversArray = useFieldArray({
    name: `steps.${stepIndex}.approval_steps.${approvalStepIdx}.approvers`,
    control,
    rules: {
      minLength: 1,
    },
  })

  if (!approvalStep) {
    return null
  }

  return (
    <Stack key={approvalStepIdx} py={4}>
      <Heading as="h2" size="sm" px={4}>
        <FormattedMessage
          id="workflow.definition.step.approval.title"
          defaultMessage="Approval Step"
          description="Title for the approval step"
        />
      </Heading>
      <Divider />
      <Stack spacing={4} px={4}>
        <Heading as="h3" size="xs">
          <FormattedMessage
            id="request.config.step.approvers.label"
            description="Label on the request step section of request configuration to set the approvers of a step"
            defaultMessage="Approver(s)"
          />
        </Heading>
        {approvalStep.approvers.map((_approver, approverIdx) => {
          return (
            <HStack key={approverIdx}>
              <Controller
                name={`steps.${stepIndex}.approval_steps.${approvalStepIdx}.approvers.${approverIdx}`}
                rules={{
                  required: true,
                }}
                control={control}
                render={({ field, fieldState }) => {
                  const onChangeApprover = (newApprover: UserPicker | PersonVariablePicker | null) => {
                    if (newApprover?.type === "user") {
                      field.onChange({
                        type: "user",
                        user: { ...newApprover, profile_image: newApprover.image_asset },
                      } satisfies WorkflowDefUserApproverInput)
                    } else if (newApprover?.type) {
                      field.onChange({
                        type: "variable",
                        variable: newApprover.variable,
                      } satisfies WorkflowDefVariableApproverInput)
                    } else {
                      field.onChange(null)
                    }
                  }
                  return (
                    <FormControl isInvalid={fieldState.invalid}>
                      <OrganizationEntityPicker
                        initialSelected={
                          field.value?.type === "user"
                            ? {
                                ...field.value.user,
                                display_name: displayPersonName(field.value.user, intl),
                                type: "user",
                                object_type: "User",
                                image_asset: field.value.user.profile_image,
                              }
                            : field.value?.type === "variable"
                              ? { variable: field.value.variable, type: "variable" }
                              : null
                        }
                        includedEntities={["person", "user", "variable"]}
                        variableTypes={approverVariableTypes}
                        onChangeUser={onChangeApprover}
                        onChangeVariable={onChangeApprover}
                        isRequired
                        isClearable
                        permission={{
                          value: getPermissionRequiredToEditStep(stepType),
                          includeAll: true,
                          defaultInviteRole: getMinimalRoleForPermission(getPermissionRequiredToEditStep(stepType)),
                        }}
                      />
                    </FormControl>
                  )
                }}
              />
              <IconButton
                aria-label={intl.formatMessage({
                  id: "workflow.definition.step.approver.remove",
                  defaultMessage: "Remove approver",
                  description: "Accessible label for the remove approver button",
                })}
                icon={<Icon as={TrashIcon} />}
                variant="unstyled"
                onClick={() => approversArray.remove(approverIdx)}
              />
            </HStack>
          )
        })}

        <HStack gap={1}>
          <Button
            variant="ghost"
            onClick={() => approversArray.append(null)}
            leftIcon={<Icon as={PlusIcon} />}
            colorScheme="brand"
          >
            <FormattedMessage
              id="workflow.definition.step.approver.add"
              defaultMessage="Add approver"
              description="Button text to add a new approver to the approval step"
            />
          </Button>
          <Tooltip
            label={intl.formatMessage({
              id: "workflow.definition.step.approver.add.tooltip",
              defaultMessage:
                "Add multiple approvers to the approval step, enabling any one of them to grant approval.",
              description: "Tooltip text for the add multiple approvers button",
            })}
            shouldWrapChildren
          >
            <Icon as={HelpIcon} color="gray.500" />
          </Tooltip>
        </HStack>
        <Controller
          name={`steps.${stepIndex}.approval_steps.${approvalStepIdx}.condition`}
          control={control}
          render={({ field }) => (
            <>
              <Flex alignItems="center">
                <ConditionalToggle
                  conditionalToggle={conditionalToggle}
                  setConditionalToggle={(value) => {
                    setConditionalToggle(value)
                    if (value === "conditional") {
                      setTimeout(() => {
                        conditionalInputRef.current?.focus()
                      }, 10)
                    }
                  }}
                  onChangeConditional={field.onChange}
                  labels={{
                    conditional: intl.formatMessage({
                      id: "workflow.definition.step.approver.conditional",
                      defaultMessage: "Require approval when...",
                      description: "Option to require approval when a condition is met",
                    }),
                    always: intl.formatMessage({
                      id: "workflow.definition.step.approver.always",
                      defaultMessage: "Always require approval",
                      description: "Option to always require approval",
                    }),
                  }}
                />
                <Tooltip
                  shouldWrapChildren
                  label={intl.formatMessage({
                    id: "workflow.definition.step.approver.add.tooltip",
                    defaultMessage:
                      "Add conditional logic to the approval step, ensuring the right approvals occur based on dynamic conditions. You may reference any criteria applied to the step, and any step before it. For example:<br></br><br></br> Require approval from James McGillicuddy if Total Contract Value is greater than $50,000.00.",
                    description: "Tooltip text for the add multiple approvers button",
                  })}
                >
                  <Icon as={HelpIcon} color="gray.500" />
                </Tooltip>
              </Flex>
              {conditionalToggle === "conditional" && (
                <ConditionalPrompt
                  ref={conditionalInputRef}
                  onChange={field.onChange}
                  getEligibleFields={() =>
                    currentStep
                      ? uniqBy(getPrevStepFields(steps, currentStep).concat(currentStep.fields), getUniqueFormFieldKey)
                      : []
                  }
                  basePath={`steps.${stepIndex}.approval_steps.${approvalStepIdx}.condition`}
                  control={control}
                />
              )}
            </>
          )}
        />
      </Stack>
    </Stack>
  )
}
