import type { VariableType } from "@brm/schema-types/types.js"
import { uniqBy } from "@brm/util/collections.js"
import { displayPersonName } from "@brm/util/names.js"
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { Fragment, useCallback, useRef, useState } from "react"
import { type Control, Controller, type FieldArrayWithId, useFieldArray, useWatch } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { isPresent } from "ts-is-present"
import CardWithHoverActions from "../../../components/CardWithHoverActions.js"
import { iconMap } from "../../../components/icons/icon-map.js"
import { ConditionalBranchIcon, HelpIcon, PlusIcon, TrashIcon, UserIcon } from "../../../components/icons/icons.js"
import { getPublicImageGcsUrl } from "../../../util/url.js"
import PickableEntityDisplay from "../../organization/PickableEntityDisplay.js"
import { translatePersonVariableName } from "../../person/util.js"
import ApprovalStepDefinition from "./ApprovalStepDefinition.js"
import ConditionalPrompt from "./ConditionalPrompt.js"
import ConditionalToggle from "./ConditionalToggle.js"
import FieldConfigList from "./FieldConfigList.js"
import FieldDefinition from "./FieldDefinition.js"
import {
  type ConfigureWorkflowFormState,
  type ConfigureWorkflowStepsFormState,
  getPrevStepFields,
  getUniqueFormFieldKey,
  OPTIONAL_APPROVAL_STEP_TYPES,
} from "./utils.js"

export default function StepDefinition({
  step,
  stepIndex,
  control,
  steps,
  approverVariableTypes,
}: {
  step: FieldArrayWithId<ConfigureWorkflowFormState, "steps", "id">
  stepIndex: number
  control: Control<ConfigureWorkflowStepsFormState>
  steps: ConfigureWorkflowFormState["steps"]
  approverVariableTypes?: VariableType[]
}) {
  const intl = useIntl()
  const [selectedApprovalStepIndex, setSelectedApprovalStepIndexState] = useState<number | null>(null)
  const [selectedFieldIndex, setSelectedFieldIndexState] = useState<number | null>(null)
  const setSelectedFieldIndex = useCallback(
    (index: number | null) => {
      setSelectedFieldIndexState(index)
      setSelectedApprovalStepIndexState(null)
    },
    [setSelectedFieldIndexState, setSelectedApprovalStepIndexState]
  )
  const setSelectedApprovalStepIndex = useCallback(
    (index: number | null) => {
      setSelectedApprovalStepIndexState(index)
      setSelectedFieldIndexState(null)
    },
    [setSelectedFieldIndexState, setSelectedApprovalStepIndexState]
  )
  const approvalStepFieldArray = useFieldArray({
    name: `steps.${stepIndex}.approval_steps`,
    control,
    rules: {
      minLength: OPTIONAL_APPROVAL_STEP_TYPES.includes(step.type) ? 0 : 1,
    },
  })

  const [conditionalToggle, setConditionalToggle] = useState<null | "conditional">(
    step.condition ? "conditional" : null
  )
  const conditionalInputRef = useRef<{ focus: () => void }>(null)

  const selectedApprovalStep =
    selectedApprovalStepIndex !== null ? step.approval_steps[selectedApprovalStepIndex] : undefined
  const selectedField = selectedFieldIndex !== null ? step.fields[selectedFieldIndex] : undefined
  const isApproverOptional = OPTIONAL_APPROVAL_STEP_TYPES.includes(step.type)
  return (
    <Stack flex={1} minHeight={0}>
      <Flex flex={1} minHeight={0}>
        <Stack flex={1} py={4} maxWidth="750px" minHeight={0} borderLeftWidth={1} borderRightWidth={1}>
          <Heading as="h2" size="sm" px={4}>
            {step.display_name}
          </Heading>
          <Divider />
          <Stack flex={1} pl={4} pr={8} minHeight={0} overflowY="auto" style={{ scrollbarGutter: "stable" }}>
            <Stack gap={4}>
              <Controller
                name={`steps.${stepIndex}.condition`}
                control={control}
                render={({ field }) => (
                  <>
                    <Flex alignItems="center">
                      <ConditionalToggle
                        conditionalToggle={conditionalToggle}
                        setConditionalToggle={(value) => {
                          setConditionalToggle(value)
                          if (value === "conditional") {
                            setTimeout(() => {
                              conditionalInputRef.current?.focus()
                            }, 10)
                          }
                        }}
                        onChangeConditional={field.onChange}
                        labels={{
                          conditional: intl.formatMessage({
                            id: "workflow.definition.step.type.conditional",
                            defaultMessage: "Run this step when...",
                            description: "Option to conditionally execute this step",
                          }),
                          always: intl.formatMessage({
                            id: "workflow.definition.step.type.always",
                            defaultMessage: "Always run this step",
                            description: "Option to always execute this step",
                          }),
                        }}
                      />
                      <Tooltip
                        shouldWrapChildren
                        label={intl.formatMessage({
                          id: "workflow.definition.step.approver.add.tooltip",
                          defaultMessage:
                            "Add conditional logic to the step, enabling the right process wherever it is needed. You may reference any criteria applied to any step before this one. For example:<br></br><br></br> Run the legal step when Requested Spend is greater than $25,000.00.",
                          description: "Tooltip text for the add multiple approvers button",
                        })}
                      >
                        <Icon as={HelpIcon} color="gray.500" />
                      </Tooltip>
                    </Flex>
                    {conditionalToggle === "conditional" && (
                      <ConditionalPrompt
                        ref={conditionalInputRef}
                        onChange={field.onChange}
                        getEligibleFields={() => uniqBy(getPrevStepFields(steps, step), getUniqueFormFieldKey)}
                        basePath={`steps.${stepIndex}.condition`}
                        control={control}
                      />
                    )}
                  </>
                )}
              />
              <FormControl isRequired>
                <Heading as="h3" size="xs" mb={2}>
                  <FormattedMessage
                    id="request.config.step.assigned_to.label"
                    description="Label on the request step section of request configuration to set the assignee of the step"
                    defaultMessage="Assigned to"
                  />
                </Heading>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <Icon as={iconMap["user"]} />
                  </InputLeftElement>
                  <Input
                    value={intl.formatMessage({
                      id: "request.config.input.assignee.default",
                      description:
                        "Default value on the assignee field shown in the request configuration. The champ is the user that is assigned to the request when it is created.",
                      defaultMessage: "Request Champ (Default)",
                    })}
                    isReadOnly
                  />
                </InputGroup>
              </FormControl>
              <Stack mb={2}>
                <Heading as="h3" size="xs">
                  {isApproverOptional ? (
                    <FormattedMessage
                      id="request.config.step.approvers.label.optional"
                      description="Label on the request step section of request configuration to set the optional approvers of a step"
                      defaultMessage="Approvers (optional)"
                    />
                  ) : (
                    <FormattedMessage
                      id="request.config.step.approvers.label"
                      description="Label on the request step section of request configuration to set the approvers of a step"
                      defaultMessage="Approvers"
                    />
                  )}
                </Heading>
                {step.approval_steps.map((approvalStep, approvalStepIdx) => {
                  return (
                    <HStack key={`steps.${stepIndex}.approval_steps.${approvalStepIdx}`}>
                      <Controller
                        name={`steps.${stepIndex}.approval_steps.${approvalStepIdx}`}
                        control={control}
                        rules={{
                          validate: (approvalStep) =>
                            approvalStep?.approvers &&
                            approvalStep.approvers.length > 0 &&
                            approvalStep.approvers.some(isPresent),
                        }}
                        render={({ fieldState }) => (
                          <CardWithHoverActions
                            flex={1}
                            onClick={() => setSelectedApprovalStepIndex(approvalStepIdx)}
                            selected={selectedApprovalStepIndex === approvalStepIdx}
                            hasError={Boolean(fieldState.error)}
                            rightAction={
                              <IconButton
                                onClick={() => {
                                  approvalStepFieldArray.remove(approvalStepIdx)
                                  if (selectedApprovalStepIndex === approvalStepIdx) {
                                    setSelectedApprovalStepIndex(null)
                                  }
                                }}
                                variant="unstyled"
                                icon={<Icon as={TrashIcon} />}
                                aria-label={intl.formatMessage({
                                  id: "workflow.definition.step.approvalStep.remove",
                                  defaultMessage: "Remove approval step",
                                  description: "Button to remove an approval step from the approval chain",
                                })}
                              />
                            }
                          >
                            <HStack justifyContent="space-between">
                              <ApprovalStepApproversDisplay
                                stepIndex={stepIndex}
                                approvalStepIndex={approvalStepIdx}
                                control={control}
                              />
                              {approvalStep.condition && <Icon as={ConditionalBranchIcon} />}
                            </HStack>
                          </CardWithHoverActions>
                        )}
                      />
                    </HStack>
                  )
                })}
                <Box>
                  <Button
                    variant="ghost"
                    onClick={() => {
                      const currentLength = approvalStepFieldArray.fields.length
                      approvalStepFieldArray.append({ approvers: [null], condition: null })
                      // Index of newly added approval step is the old array length after append
                      setSelectedApprovalStepIndex(currentLength)
                    }}
                    leftIcon={<Icon as={PlusIcon} />}
                    colorScheme="brand"
                  >
                    <FormattedMessage
                      id="workflow.definition.step.approvalStep.add"
                      defaultMessage="Add approval step"
                      description="Button text to add a new approval step to the approver chain"
                    />
                  </Button>
                </Box>
              </Stack>
              <Flex direction="column">
                <Heading size="xs" fontWeight="semibold">
                  <FormattedMessage
                    id="request.config.step.criteria.label"
                    description="Label on the request step section of request configuration to set the criteria of a step"
                    defaultMessage="Criteria"
                  />
                </Heading>
                <FieldConfigList
                  control={control}
                  stepType={step.type}
                  stepIndex={stepIndex}
                  setSelectedFieldIndex={setSelectedFieldIndex}
                  selectedFieldIndex={selectedFieldIndex}
                />
              </Flex>
            </Stack>
          </Stack>
        </Stack>
        {selectedApprovalStepIndex !== null && selectedApprovalStep ? (
          <Flex flexDir="column" flex={1} minHeight={0}>
            <ApprovalStepDefinition
              key={selectedApprovalStepIndex}
              approvalStep={selectedApprovalStep}
              approvalStepIdx={selectedApprovalStepIndex}
              stepIndex={stepIndex}
              control={control}
              stepType={step.type}
              approverVariableTypes={approverVariableTypes}
              // steps={steps}
              // step={step}
              // contextSchema={contextSchema}
            />
          </Flex>
        ) : selectedFieldIndex !== null && selectedField ? (
          <Flex flexDir="column" flex={1} minHeight={0}>
            <FieldDefinition
              key={selectedFieldIndex}
              fieldIndex={selectedFieldIndex}
              stepIndex={stepIndex}
              control={control}
              field={selectedField}
            />
          </Flex>
        ) : null}
      </Flex>
    </Stack>
  )
}

function ApprovalStepApproversDisplay({
  stepIndex,
  approvalStepIndex,
  control,
}: {
  stepIndex: number
  approvalStepIndex: number
  control: Control<ConfigureWorkflowStepsFormState>
}) {
  const intl = useIntl()

  const approversArray = useWatch({ control, name: `steps.${stepIndex}.approval_steps.${approvalStepIndex}.approvers` })

  const validApprovers = approversArray.filter(isPresent)
  if (!approversArray || validApprovers.length === 0) {
    return <Avatar icon={<Icon as={UserIcon} />} />
  }

  return (
    <HStack gap={1} textOverflow="ellipsis">
      {validApprovers.map((a, idx) => {
        return (
          <Fragment key={idx}>
            {idx > 0 && (
              <Text color="gray.500">
                <FormattedMessage
                  id="workflow.definitions.step.approvalStep.approvers.or"
                  description="Text to display between approvers"
                  defaultMessage="or"
                />
              </Text>
            )}
            {a.type === "user" ? (
              <PickableEntityDisplay
                displayText={displayPersonName(a.user, intl)}
                image={getPublicImageGcsUrl(a.user.profile_image?.gcs_file_name)}
              />
            ) : (
              <PickableEntityDisplay displayText={translatePersonVariableName(a.variable, intl)} />
            )}
          </Fragment>
        )
      })}
    </HStack>
  )
}
