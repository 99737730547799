import type { FieldCategory, WorkflowKind, WorkflowRunStatus } from "@brm/schema-types/types.js"
import { FieldCategorySchema, WorkflowKindSchema, WorkflowRunStatusSchema } from "@brm/schemas"
import { getEnumMemberTitle } from "@brm/util/schema.js"

export function displayWorkflowKind(kind: WorkflowKind) {
  return getEnumMemberTitle(kind, WorkflowKindSchema)
}

export function displayWorkflowStatus(status: WorkflowRunStatus) {
  return getEnumMemberTitle(status, WorkflowRunStatusSchema)
}

export function displayFieldCategory(category: FieldCategory) {
  return getEnumMemberTitle(category, FieldCategorySchema)
}
