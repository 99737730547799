import type {
  DateString,
  FieldMetadataWithSuggestions,
  FieldSourceOutputProperties,
  LegalAgreementInput,
  Suggestion,
} from "@brm/schema-types/types.js"
import { derivedDecisionDate } from "@brm/type-helpers/legal.js"
import { max } from "@brm/util/date-time.js"
import { formatDate, formatDuration } from "@brm/util/format-date-time.js"
import { getSchemaAtPath, getTitle } from "@brm/util/schema.js"
import { capitalize } from "@brm/util/string.js"
import { isEmpty } from "@brm/util/type-guard.js"
import { Temporal } from "@js-temporal/polyfill"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import type { FieldValues, UseFormReturn } from "react-hook-form"
import type { IntlShape } from "react-intl"
import { isPresent } from "ts-is-present"
import type { ReadonlyDeep } from "type-fest"
import type { SchemaFormFieldApproval } from "../components/SchemaForm/types.js"
import { FIELD_SOURCE_COLORS } from "../components/SchemaForm/utils.js"

export function initializeReactHookFromState<T extends FieldValues>(form: UseFormReturn<T>) {
  // It's important to access this property once, as react-hook-form optimizes performance by only updating
  // `formState` if it was initially accessed once (by using a Proxy). If it was only accessed later in the
  // callbacks, it would not have been updated before. We use Reflect.get() so that dead code elimination doesn't
  // remove it. See https://react-hook-form.com/docs/useform/formstate#rules
  Reflect.get(Reflect.get(form, "formState"), "isDirty") satisfies (typeof form)["formState"]["isDirty"]
  Reflect.get(Reflect.get(form, "formState"), "dirtyFields") satisfies (typeof form)["formState"]["dirtyFields"]
}

export interface NewOption {
  isNew: boolean
  colorScheme?: string
  fieldSource?: FieldSourceOutputProperties
}

export interface DateShortcut {
  displayName: string
  date: DateString
}

interface DateShortcutConfig {
  anchor: DateString
  anchorDisplayName: string
  offsets: Temporal.Duration[]
}

/** Returns a boolean indicating whether the field is new or not as well as the colorscheme for the option. */
export function isNewOption(
  optionFieldSources?: Suggestion["field_sources"],
  fieldMetadata?: FieldMetadataWithSuggestions,
  fieldApproval?: SchemaFormFieldApproval
): NewOption {
  // If field is approved we do not care to show the new value tag
  if (fieldApproval?.fieldIsApproved) {
    return { isNew: false }
  }
  const updatedAt = fieldMetadata?.updated_at
  // A field can either be verified or approved
  const verifiedAt = fieldMetadata?.verified_at || fieldApproval?.updated_at
  if (isEmpty(optionFieldSources)) {
    return { isNew: false }
  }

  let fieldUpdatedAt: Temporal.Instant | undefined
  if (updatedAt && verifiedAt) {
    fieldUpdatedAt = max(Temporal.Instant.from(updatedAt), Temporal.Instant.from(verifiedAt))
  } else if (verifiedAt) {
    fieldUpdatedAt = Temporal.Instant.from(verifiedAt)
  } else if (updatedAt) {
    fieldUpdatedAt = Temporal.Instant.from(updatedAt)
  }

  // Once the first occurrence of the field is shown it should never be shown as new again
  const oldestFieldSource = optionFieldSources
    ?.filter((s): s is FieldMetadataWithSuggestions & { source_updated_at: string } => !isEmpty(s.source_updated_at))
    ?.sort((a, b) =>
      Temporal.Instant.compare(Temporal.Instant.from(a.source_updated_at), Temporal.Instant.from(b.source_updated_at))
    )[0]

  const colorScheme = oldestFieldSource?.type && FIELD_SOURCE_COLORS[oldestFieldSource.type]
  return {
    isNew: !!(
      !fieldUpdatedAt ||
      (oldestFieldSource &&
        Temporal.Instant.compare(fieldUpdatedAt, Temporal.Instant.from(oldestFieldSource.source_updated_at)) === -1)
    ),
    colorScheme,
    fieldSource: oldestFieldSource,
  }
}

/**
 * Looks through all suggestion values of a field and returns the first one that is new as well as its colorscheme.
 * If no suggestion is new, returns undefined.
 */
export function fieldHasNewOption(
  fieldMetadata?: FieldMetadataWithSuggestions,
  fieldApproval?: SchemaFormFieldApproval
): NewOption | undefined {
  return fieldMetadata?.suggestions
    ?.map((suggestion) => isNewOption(suggestion.field_sources, fieldMetadata, fieldApproval))
    .find(({ isNew }) => isNew)
}

/** Returns an array of new options given a document */
export function getNewOptionsByDocumentId(documentId: string, fields: FieldMetadataWithSuggestions[]): NewOption[] {
  return fields
    .map((fieldMetadata) => {
      const filteredSuggestion = fieldMetadata?.suggestions?.find((s) =>
        s.field_sources.find((source) => source.id === documentId && source.type === "document")
      )
      return filteredSuggestion ? fieldHasNewOption({ ...fieldMetadata, suggestions: [filteredSuggestion] }) : undefined
    })
    .filter(isPresent)
}

function generateDateShortcut(
  { offsets, anchorDisplayName, anchor }: DateShortcutConfig,
  intl: IntlShape
): DateShortcut[] {
  const achorDate = Temporal.PlainDate.from(anchor)
  return offsets
    .map((offset) => ({
      date: achorDate.add(offset),
      displayName:
        offset.sign > 0
          ? intl.formatMessage(
              {
                id: "dateShortcut.add",
                defaultMessage: "{duration} after {anchorName}",
                description: "Label for date shortcut when adding duration to anchor date",
              },
              { duration: formatDuration(intl, offset, { signDisplay: "never" }), anchorName: anchorDisplayName }
            )
          : offset.sign < 0
            ? intl.formatMessage(
                {
                  id: "dateShortcut.subtract",
                  defaultMessage: "{duration} before {anchorName}",
                  description: "Label for date shortcut when subtracting duration from anchor date",
                },
                {
                  duration: formatDuration(intl, offset, { signDisplay: "never" }),
                  anchorName: anchorDisplayName,
                }
              )
            : capitalize(anchorDisplayName),
    }))
    .sort((a, b) => Temporal.PlainDate.compare(Temporal.PlainDate.from(a.date), Temporal.PlainDate.from(b.date)))
    .map((shortcut) => ({
      ...shortcut,
      date: shortcut.date.toString(),
    }))
}

export function getLegalDateShortcuts(
  fieldName: string,
  rootSchema: ReadonlyDeep<JSONSchema>,
  legalFields: Pick<
    Partial<LegalAgreementInput>,
    | "start_date"
    | "auto_renewal_opt_out_period"
    | "invoice_interval"
    | "agreement_type"
    | "first_invoice_date"
    | "end_date"
  >,
  intl: IntlShape
): DateShortcut[] | undefined {
  switch (fieldName) {
    case "end_date": {
      if (!legalFields.start_date) {
        return undefined
      }
      const startDateSchema = getSchemaAtPath(rootSchema, "start_date")
      const config: DateShortcutConfig = {
        anchor: legalFields.start_date,
        anchorDisplayName: getTitle("start_date", startDateSchema).toLowerCase(),
        offsets: [
          Temporal.Duration.from({ months: 1 }),
          Temporal.Duration.from({ months: 2 }),
          Temporal.Duration.from({ months: 6 }),
          Temporal.Duration.from({ years: 1 }),
          Temporal.Duration.from({ years: 2 }),
        ],
      }
      return generateDateShortcut(config, intl)
    }
    case "decision_date": {
      const derivedDecisionDateObject = derivedDecisionDate(legalFields)
      // Not enough information, use default shortcuts
      if (!derivedDecisionDateObject?.decision_date) {
        return undefined
      }
      const config = {
        anchor: derivedDecisionDateObject.decision_date,
        anchorDisplayName: formatDate(intl, derivedDecisionDateObject.decision_date, {}),
        offsets: [
          Temporal.Duration.from({ days: -1 }),
          Temporal.Duration.from({ weeks: -1 }),
          Temporal.Duration.from({ months: -1 }),
          Temporal.Duration.from({ months: -6 }),
          Temporal.Duration.from({ years: -1 }),
          Temporal.Duration.from({ years: -2 }),
        ],
      }
      return (
        generateDateShortcut(config, intl)
          // Filter out shortcuts before start date
          .filter(
            (shortcut) =>
              !legalFields.start_date ||
              Temporal.PlainDate.compare(
                Temporal.PlainDate.from(shortcut.date),
                Temporal.PlainDate.from(legalFields.start_date)
              ) >= 0
          )
      )
    }
    case "first_invoice_date": {
      if (!legalFields.start_date) {
        return undefined
      }
      const config: DateShortcutConfig = {
        anchor: legalFields.start_date,
        anchorDisplayName: getTitle(fieldName, getSchemaAtPath(rootSchema, "start_date")).toLowerCase(),
        offsets: [
          Temporal.Duration.from({ days: 0 }),
          Temporal.Duration.from({ days: 1 }),
          Temporal.Duration.from({ weeks: 1 }),
          Temporal.Duration.from({ weeks: 2 }),
          Temporal.Duration.from({ months: 1 }),
          Temporal.Duration.from({ years: 1 }),
        ],
      }
      return [
        {
          displayName: intl.formatMessage({
            id: "dateShortcut.inOneWeek",
            defaultMessage: "In a week",
            description: "Label for date shortcut when selecting one week in the future",
          }),
          date: Temporal.PlainDate.from(Temporal.Now.plainDateISO()).toString(),
        },
        ...generateDateShortcut(config, intl),
      ]
    }
    default:
      return undefined
  }
}

/**
 * Gets default date shortcuts for the date picker.
 * If sign is 1, the shortcuts will be in the future. If sign is -1, the shortcuts will be in the past.
 */
export const getDefaultDateShortcuts = (
  intl: IntlShape,
  options?: { sign?: 1 | -1; additionalShortcuts?: DateShortcut[] } | undefined
): DateShortcut[] => {
  const today = Temporal.PlainDate.from(Temporal.Now.plainDateISO())
  const defaultDates = [
    {
      displayName: intl.formatMessage({
        id: "dateShortcut.yesterday",
        defaultMessage: "Yesterday",
        description: "Label for date shortcut when selecting yesterday’s date",
      }),
      date: today.subtract(Temporal.Duration.from({ days: 1 })).toString(),
    },
    {
      displayName: intl.formatMessage({
        id: "dateShortcut.weekAgo",
        defaultMessage: "A week ago",
        description: "Label for date shortcut when selecting one week ago",
      }),
      date: today.subtract(Temporal.Duration.from({ weeks: 1 })).toString(),
    },
    {
      displayName: intl.formatMessage({
        id: "dateShortcut.monthAgo",
        defaultMessage: "A month ago",
        description: "Label for date shortcut when selecting one month ago",
      }),
      date: today.subtract(Temporal.Duration.from({ months: 1 })).toString(),
    },
    {
      displayName: intl.formatMessage({
        id: "dateShortcut.yearAgo",
        defaultMessage: "A year ago",
        description: "Label for date shortcut when selecting one year ago",
      }),
      date: today.subtract(Temporal.Duration.from({ years: 1 })).toString(),
    },
    {
      displayName: intl.formatMessage({
        id: "dateShortcut.tomorrow",
        defaultMessage: "Tomorrow",
        description: "Label for date shortcut when selecting tomorrow",
      }),
      date: today.add(Temporal.Duration.from({ days: 1 })).toString(),
    },
    {
      displayName: intl.formatMessage({
        id: "dateShortcut.weekInFuture",
        defaultMessage: "In a week",
        description: "Label for date shortcut when selecting one week in the future",
      }),
      date: today.add(Temporal.Duration.from({ weeks: 1 })).toString(),
    },
    {
      displayName: intl.formatMessage({
        id: "dateShortcut.monthInFuture",
        defaultMessage: "In a month",
        description: "Label for date shortcut when selecting one month in the future",
      }),
      date: today.add(Temporal.Duration.from({ months: 1 })).toString(),
    },
    {
      displayName: intl.formatMessage({
        id: "dateShortcut.yearInFuture",
        defaultMessage: "In a year",
        description: "Label for date shortcut when selecting one year in the future",
      }),
      date: today.add(Temporal.Duration.from({ years: 1 })).toString(),
    },
  ]

  return [...defaultDates, ...(options?.additionalShortcuts ?? [])]
    .filter(
      (shortcut) =>
        !options?.sign || Temporal.PlainDate.compare(Temporal.PlainDate.from(shortcut.date), today) === options.sign
    )
    .sort((a, b) => Temporal.PlainDate.compare(Temporal.PlainDate.from(a.date), Temporal.PlainDate.from(b.date)))
}
