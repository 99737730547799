import type { PersonStatus, VariableType } from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import type { IntlShape } from "react-intl"

export const displayPersonStatus = (status: PersonStatus, intl: IntlShape) => {
  switch (status) {
    case "active":
      return intl.formatMessage({
        id: "person.status.current",
        defaultMessage: "Current",
        description: "The label for the person status `current`",
      })
    case "inactive":
      return intl.formatMessage({
        id: "person.status.former",
        defaultMessage: "Former",
        description: "The label for the person status `former`",
      })
    case "pending":
      return intl.formatMessage({
        id: "person.status.pending",
        defaultMessage: "Pending",
        description: "The label for the person status `pending`",
      })
    default:
      unreachable(status)
  }
}

export const translatePersonVariableName = (variable: VariableType, intl: IntlShape) => {
  switch (variable) {
    case "manager":
      return intl.formatMessage({
        id: "person.variable.manager",
        defaultMessage: "Manager",
        description: "The label for the person variable `manager`",
      })
    default:
      unreachable(variable)
  }
}
