import { displayPersonName } from "@brm/util/names.js"
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Tooltip,
  chakra,
  forwardRef,
  useDisclosure,
  type ButtonProps,
  type LinkProps,
} from "@chakra-ui/react"
import { useFlags } from "launchdarkly-react-client-sdk"
import { useHotkeys } from "react-hotkeys-hook"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import braimLogo from "../../../assets/braim.svg"
import { useGetNotificationV1UnreadQuery, useGetUserV1WhoamiQuery } from "../../app/services/generated-api.js"
import StartWorkflowModal from "../../features/workflows/run/start/StartWorkflowModal.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import BrmLogo from "../BrmLogo.js"
import { Link } from "../Link.js"
import { RevealingButtonGroup, RevealingIconButton } from "../RevealingIconButton.js"
import {
  AgreementIcon,
  CalendarIcon,
  DotIcon,
  InboxIcon,
  PlusIcon,
  RequestIcon,
  SecurityIcon,
  SettingsIcon,
  SupportChatIcon,
  SupportGuidesIcon,
  ToolsIcon,
  UserIcon,
  UsersIcon,
  VendorIcon,
} from "../icons/icons.js"
import { ModifierKey } from "../labels/shortcuts.js"
import { LogOutButton } from "./LogOutButton.js"
import { NAVBAR_WIDTH, NAVBAR_Z_INDEX } from "./constants.js"

const NavButton = forwardRef<ButtonProps, "button">(function NavButton(props, ref) {
  return <Button variant="ghost" justifyContent="flex-start" flexShrink={0} {...props} ref={ref} />
})

const NavLink = forwardRef<ButtonProps & LinkProps & { to: string }, "a">(function NavLink(props, ref) {
  const location = useLocation()
  return (
    <NavButton
      as={Link}
      aria-current={location.pathname.startsWith(props.to) ? "page" : "false"}
      flexShrink={0}
      ref={ref}
      {...props}
    />
  )
})

export default function NavSideBar() {
  const intl = useIntl()
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const { data: unreadQueryResult } = useGetNotificationV1UnreadQuery()

  const navigate = useNavigate()
  const location = useLocation()
  const { id } = useParams()
  const { pendoResourceCenterEnabled } = useFlags()

  const startWorkflowModal = useDisclosure()

  useHotkeys(
    ["shift+mod+k"],
    () => {
      navigate("/braim")
    },
    { enableOnFormTags: ["input", "select", "textarea"] }
  )

  const newWorkflowLabel = intl.formatMessage({
    id: "Sor.nav.newRequest",
    description: "Sor nav link to create a new request",
    defaultMessage: "Create new request",
  })
  const addAgreementLabel = intl.formatMessage({
    defaultMessage: "Add agreement",
    description: "Sor nav link to create a new agreement",
    id: "Sor.nav.newAgreement",
  })
  return (
    <Flex
      flexShrink={0}
      flexGrow={0}
      position="sticky"
      left={0}
      top={0}
      flexDir="column"
      as="nav"
      zIndex={NAVBAR_Z_INDEX}
      height="100vh"
      // The way the sidebar expands is by having the inner Flex grow beyond the width of the outer Flex with
      // overflow: visible. This is better than `position: absolute` on the content, because that would take the
      // content out of the layout flow and prevent the root element scrolling.
      width={`${NAVBAR_WIDTH}px`}
      overflow="visible"
      sx={{ contain: "layout size style" }}
    >
      <Flex
        className="nav-sidebar-expandable"
        flexDir="column"
        p={1}
        overflow="hidden"
        borderRightWidth={1}
        bg="white"
        height="100%"
        width="100%"
        transition="width 200ms ease-in-out"
        sx={{
          "&:is(:hover, :has(:focus-visible))": {
            width: "13.75rem",
          },
          "&:not(:is(:hover, :has(:focus-visible))) .revealing-button-group": {
            overflow: "hidden",
          },
          "&:not(:is(:hover, :has(:focus-visible))) .revealing-icon-button": {
            width: 0,
            minWidth: 0,
          },
        }}
      >
        <Flex my={1} p={2} px={3} alignItems="center">
          <Link
            to="/"
            aria-label={intl.formatMessage({
              id: "navHeader.homeLink",
              description: "Label for the logo that links to the home page",
              defaultMessage: "Home",
            })}
            gap={2}
            display="flex"
            fontWeight="bold"
            overflow="hidden"
            _hover={{
              textDecoration: "none",
            }}
          >
            <BrmLogo flexShrink={0} flexGrow={0} flexBasis="50px" minW="50px" w="50px" h="16px" />
          </Link>
        </Flex>

        <Flex
          flexDir="column"
          gap={2}
          flexGrow={1}
          overflowY="auto"
          p={1}
          pb={2}
          mt={2}
          sx={{
            scrollbarWidth: "none",
            "::-webkit-scrollbar": { display: "none" },
          }}
        >
          <NavLink
            to="/inbox"
            leftIcon={
              <>
                <Icon
                  height={4}
                  viewBox="0 0 200 200"
                  color="brand.300"
                  position="absolute"
                  left={3.5}
                  bottom={3.5}
                  opacity={unreadQueryResult?.unread ? 1 : 0}
                  as={DotIcon}
                  transition="opacity .3s ease-in-out"
                />
                <Icon as={InboxIcon} />
              </>
            }
          >
            <FormattedMessage id="Sor.nav.inbox" description="Sor nav link to view inbox" defaultMessage="Inbox" />
          </NavLink>
          <RevealingButtonGroup flexShrink={0}>
            <NavLink to="/requests" flexGrow={1} leftIcon={<Icon as={RequestIcon} />}>
              <FormattedMessage
                id="Sor.nav.requests"
                description="Sor nav link to view requests"
                defaultMessage="Requests"
              />
            </NavLink>
            <Tooltip label={newWorkflowLabel} openDelay={200}>
              <RevealingIconButton
                icon={<Icon as={PlusIcon} />}
                aria-label={newWorkflowLabel}
                {...startWorkflowModal.getButtonProps()}
              />
            </Tooltip>
          </RevealingButtonGroup>
          {startWorkflowModal.isOpen && (
            <StartWorkflowModal
              {...startWorkflowModal}
              // This modal is triggered from a sidebar button that is revealed on hover. Returning focus to that button causes
              // broken tooltip rendering behavior on a now-hidden button
              returnFocusOnClose={false}
              toolId={location.pathname.startsWith("/tools/") && id ? id : undefined}
            />
          )}
          <NavLink to="/renewal-calendar" leftIcon={<Icon as={CalendarIcon} />}>
            <FormattedMessage
              id="Sor.nav.renewalCalendar"
              description="Sor nav link to view renewal calendar"
              defaultMessage="Renewals"
            />
          </NavLink>
          <NavLink to="/tools" leftIcon={<Icon as={ToolsIcon} />}>
            <FormattedMessage id="Sor.nav.tools" description="Sor nav link to view tool list" defaultMessage="Tools" />
          </NavLink>
          <NavLink to="/vendors" leftIcon={<Icon as={VendorIcon} />}>
            <FormattedMessage
              id="Sor.nav.vendors"
              description="Sor nav link to view vendor list"
              defaultMessage="Vendors"
            />
          </NavLink>
          <RevealingButtonGroup flexShrink={0}>
            <NavLink to="/agreements" flexGrow={1} leftIcon={<Icon as={AgreementIcon} />}>
              <FormattedMessage
                id="Sor.nav.legalAgreements"
                description="Sor nav link to view legal agreements list"
                defaultMessage="Agreements"
              />
            </NavLink>
            <Tooltip label={addAgreementLabel} openDelay={200}>
              <RevealingIconButton
                as={Link}
                to="/agreements#upload=true"
                icon={<Icon as={PlusIcon} />}
                aria-label={addAgreementLabel}
              />
            </Tooltip>
          </RevealingButtonGroup>
          <NavLink to="/people" leftIcon={<Icon as={UsersIcon} />}>
            <FormattedMessage id="Sor.nav.people" description="Sor nav link to view people" defaultMessage="People" />
          </NavLink>
          <NavLink to="/security" leftIcon={<Icon as={SecurityIcon} />}>
            <FormattedMessage
              id="Sor.nav.security"
              description="Sor nav link to view security"
              defaultMessage="Security"
            />
          </NavLink>
          <Tooltip
            label={
              <FormattedMessage
                id="braim.open.hover"
                description="Hover tooltip on braim logo that opens the braim page"
                defaultMessage="Ask your AI assistant<br></br>{modifierKey}+⇧+K"
                values={{ modifierKey: <ModifierKey /> }}
              />
            }
          >
            <NavLink to="/braim" leftIcon={<Image src={braimLogo} height="1lh" />}>
              <chakra.span>
                <FormattedMessage
                  id="navHeader.braim"
                  description="Label for the logo that links to the braim page"
                  defaultMessage="Ask <blue>AI</blue>"
                  values={{ blue: (chunks) => <chakra.span color="blueLight.700">{chunks}</chakra.span> }}
                />
              </chakra.span>
            </NavLink>
          </Tooltip>

          {pendoResourceCenterEnabled && (
            <NavButton
              mt="auto"
              onClick={() => {
                window.pendo?.BuildingBlocks?.BuildingBlockResourceCenter?.getResourceCenter().show()
                window.Pylon?.("hide")
              }}
              leftIcon={<Icon as={SupportGuidesIcon} />}
            >
              <FormattedMessage
                id="nav.menu.guides"
                description="Navigation menu item link to launch support guides"
                defaultMessage="Support Guides"
              />
            </NavButton>
          )}
          <NavButton
            mt={pendoResourceCenterEnabled ? undefined : "auto"}
            onClick={() => {
              window.Pylon?.("show")
              window.pendo?.BuildingBlocks?.BuildingBlockResourceCenter?.dismissResourceCenter()
            }}
            leftIcon={<Icon as={SupportChatIcon} />}
          >
            <FormattedMessage
              id="nav.menu.chat"
              description="Navigation menu item link to chat with support for help"
              defaultMessage="Chat with support"
            />
          </NavButton>
          <NavLink to="/settings" leftIcon={<Icon as={SettingsIcon} />}>
            <FormattedMessage
              id="Sor.nav.settings"
              description="Sor nav link to view settings"
              defaultMessage="Settings"
            />
          </NavLink>
        </Flex>

        {/* User */}
        <Flex gap={4} p={2} pt={4} borderTopWidth={1} alignItems="center" whiteSpace="nowrap">
          <Link to="/settings/profile" display="contents">
            <Avatar
              src={getPublicImageGcsUrl(whoami?.profile_image?.gcs_file_name)}
              icon={<Icon as={UserIcon} />}
              boxSize={6}
            />
            <Flex direction="column" flexGrow={1} minW={0}>
              <chakra.span fontWeight="medium" textOverflow="ellipsis" overflow="hidden">
                {whoami && displayPersonName(whoami, intl)}
              </chakra.span>
              <chakra.span color="muted" textOverflow="ellipsis" overflow="hidden">
                {whoami?.email}
              </chakra.span>
            </Flex>
          </Link>
          <LogOutButton />
        </Flex>
      </Flex>
    </Flex>
  )
}
