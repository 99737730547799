import { isRichTextType } from "@brm/schema-helpers/rich-text/rich-text.js"
import { isDocumentOrURLStringType, isDocumentType } from "@brm/schema-helpers/schema.js"
import type {
  FieldMetadataWithSuggestions,
  FieldSourceType,
  LegalAgreementFieldsMetadata,
  LegalWorkflowFieldsMetadata,
  User,
  WorkflowFieldsMetadata,
} from "@brm/schema-types/types.js"
import { isCompliantWithDocumentType } from "@brm/type-helpers/schema.js"
import { displayPersonName } from "@brm/util/names.js"
import { getSchemaAtPath, getValueAtPath } from "@brm/util/schema.js"
import { isObject } from "@brm/util/type-guard.js"
import type * as cfWorkerJsonSchema from "@cfworker/json-schema"
import { Temporal } from "@js-temporal/polyfill"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import type { ColorScheme } from "chakra-react-select"
import { capitalCase } from "change-case"
import { Traverse } from "neotraverse/modern"
import objectPath from "object-path"
import type { FieldNamesMarkedBoolean } from "react-hook-form"
import type { IntlShape } from "react-intl"
import type { PartialDeep, ReadonlyDeep } from "type-fest"
import { fillIdsAndObjectTypesAlongPath, isEmptyString } from "../../features/workflows/run/utils.js"
import { getSchemaCustomErrorMessage, isToolListingArrayType } from "../../util/json-schema.js"
import { log } from "../../util/logger.js"
import { isEmptyRichText } from "../RichTextEditor/util/common.js"

export const FORM_MAX_WIDTH = 800
export type SchemaFormFieldsMetadata =
  | LegalAgreementFieldsMetadata
  | WorkflowFieldsMetadata
  | LegalWorkflowFieldsMetadata

export const FIELD_SOURCE_COLORS: Partial<Record<FieldSourceType, ColorScheme>> = {
  link: "blue",
  listing: "brand",
  document: "purple",
  transaction: "purple",
  derived: "purple",
  user: "brand",
}

export const getDefaultUserFieldSource = (intl: IntlShape, whoami: User): FieldMetadataWithSuggestions => {
  const now = Temporal.Now.instant().toString()
  return {
    type: "user",
    id: whoami.id,
    updated_at: now,
    source_display_name: displayPersonName(whoami, intl),
    assigned_by_metadata: null,
    verified: true,
    verified_at: now,
    verified_by: whoami.id,
  }
}

export const fieldHasFormValue = (schema: ReadonlyDeep<JSONSchema> | undefined, value: unknown): boolean => {
  if (value === null) {
    return false
  }
  if (value === undefined) {
    return false
  }
  if (isCompliantWithDocumentType(schema, value)) {
    return value.compliant !== undefined
  }
  if (isToolListingArrayType(schema, value)) {
    return value.length > 0
  }
  if (isRichTextType(schema, value)) {
    return !isEmptyRichText(value)
  }
  return true
}

export const formatValidationErrors = ({
  errors,
  schema,
  fieldName,
  handleCustomErrorMessage,
}: {
  errors: cfWorkerJsonSchema.OutputUnit[]
  schema: ReadonlyDeep<JSONSchema>
  fieldName: string | undefined
  /** Allows caller to handle errors with custom messages for specific schemas or keywords. If not specified or returns undefined, will default to using the error message from the schema. */
  handleCustomErrorMessage?: (
    schema: ReadonlyDeep<JSONSchema>,
    /** The keyword on the schema that caused the error */
    errorKeyword: string
  ) => string | undefined
}): string => {
  log.warn("Validation for field", fieldName, { errors, schema })
  // The errors are sentences that end with a full stop, so we join them with a space rather than a comma.
  return errors
    .map(
      ({ keyword, error }) =>
        handleCustomErrorMessage?.(schema, keyword) ?? getSchemaCustomErrorMessage(schema, keyword) ?? error
    )
    .join(" ")
}

/**
 * Given a path find the closest fields_metadata with data as the root object.
 * The response will also return a fieldMetadataPath which can be used to access the fieldMetadata of the given path.
 */
export const findClosestFieldsMetadata = (
  pathArray: (string | number)[],
  i: number,
  data: unknown
): { fieldsMetadata: SchemaFormFieldsMetadata; fieldMetadataPath: (string | number)[] } | undefined => {
  if (i === 0) {
    return undefined
  }
  const parentValue = getValueAtPath(data, pathArray.slice(0, i - 1))
  const fieldsMetadata =
    isObject(parentValue) &&
    "fields_metadata" in parentValue &&
    (parentValue.fields_metadata as SchemaFormFieldsMetadata)
  if (fieldsMetadata) {
    return { fieldsMetadata, fieldMetadataPath: pathArray.slice(i - 1, pathArray.length) }
  }
  return findClosestFieldsMetadata(pathArray, i - 1, data)
}

export function getDerivedSourceDisplayName(intl: IntlShape, fieldMetadata: FieldMetadataWithSuggestions) {
  switch (fieldMetadata.derived_method) {
    case "tcv_summation":
      return intl.formatMessage({
        id: "field.source.derived.tcvSummation.tooltip",
        description: "Tooltip text on BRM TCV summation source indicator",
        defaultMessage: "Sum of all extracted total contract values",
      })
    default: {
      if (fieldMetadata.derived_from) {
        return intl.formatMessage(
          {
            id: "field.source.derived.default.tooltip",
            description: "Tooltip text on BRM derived field source indicator",
            defaultMessage: "Data derived from {derivedValues}",
          },
          // TODO pass in parentSchema to get the field name's title
          { derivedValues: intl.formatList(fieldMetadata.derived_from.map((field) => capitalCase(field))) }
        )
      }
    }
  }

  return undefined
}

/**
 * Reads dirty fields from the existing form values and returns the minimal patch of those fields in the same shape.
 * This will also write any uuids and object types found along the path of the dirty field into the draft state.
 */
export const updateFormFieldsWithDirtyFields = <T extends object>(
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<T>>>,
  allValues: PartialDeep<T>,
  rootSchema: ReadonlyDeep<JSONSchema>
): T => {
  const dirtyValuesPatch = {} as T
  new Traverse(dirtyFields, { includeSymbols: false }).forEach(function (traversal, value) {
    fillIdsAndObjectTypesAlongPath(dirtyValuesPatch, allValues, traversal.path as Exclude<PropertyKey, symbol>[])
    const path = traversal.path.join(".")
    // Special handling for documents if any part of them is edited, send the whole document object
    // Because we always want to deal with whole documents
    const schema = getSchemaAtPath(rootSchema, traversal.path as Exclude<PropertyKey, symbol>[])
    if (isObject(schema) && (isDocumentType(schema) || isDocumentOrURLStringType(schema))) {
      const documentValue = objectPath.get(allValues, path)
      if (value === true || Object.values(value || {}).some((dirtySubField) => dirtySubField === true)) {
        objectPath.set(dirtyValuesPatch, path, documentValue)
      }
    }
    // At leaf nodes, value is a boolean indicating if that field is dirty
    if (value === true) {
      const valueAtPath = objectPath.get(allValues, path)
      objectPath.set(dirtyValuesPatch, path, !isEmptyString(valueAtPath) ? valueAtPath : null)
    }
  })
  return dirtyValuesPatch
}
