import type { CurrencyAmount, CurrencyRange, SpendByCurrency } from "@brm/schema-types/types.js"
import { SpendByCurrencySchema } from "@brm/schemas"
import { isObject } from "@brm/util/type-guard.js"
import { isPresent } from "ts-is-present"

export const DEFAULT_CURRENCY = "USD"

// Default USD options
export const STANDARD_CURRENCY_RANGES: CurrencyRange[] = [
  { min: "0", max: "4999", currency_code: DEFAULT_CURRENCY },
  { min: "5000", max: "24999", currency_code: DEFAULT_CURRENCY },
  { min: "25000", max: "49999", currency_code: DEFAULT_CURRENCY },
  { min: "50000", max: "99999", currency_code: DEFAULT_CURRENCY },
  { min: "100000", max: "249999", currency_code: DEFAULT_CURRENCY },
  { min: "250000", max: null, currency_code: DEFAULT_CURRENCY },
]

// Create a function that checks if the field name is a key of the type SpendByCurrency
const isSpendByCurrencyKey = (fieldName: string): fieldName is keyof SpendByCurrency => {
  return fieldName in SpendByCurrencySchema.properties
}

export const getCurrencyAmountsFromParent = (parentValue: unknown, fieldName?: string): CurrencyAmount[] => {
  const spendForField =
    fieldName &&
    isSpendByCurrencyKey(fieldName) &&
    isObject(parentValue) &&
    "spend_by_currency_code" in parentValue &&
    isObject(parentValue.spend_by_currency_code) &&
    fieldName in (parentValue.spend_by_currency_code as SpendByCurrency)
      ? (parentValue.spend_by_currency_code as SpendByCurrency)[fieldName]
      : null

  if (!spendForField) {
    return []
  }

  return Object.entries(spendForField)
    .map(([currencyCode, amount]): CurrencyAmount | null => {
      if (!amount) return null
      return {
        currency_code: currencyCode,
        amount,
      }
    })
    .filter(isPresent)
}
