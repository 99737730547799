import type {
  FieldMetadataWithSuggestions,
  FieldSourceOutputProperties,
  FieldSourceType,
  LegalClausesFieldsMetadata,
} from "@brm/schema-types/types.js"
import { LegalClausesSchema } from "@brm/schemas"
import { formatDate } from "@brm/util/format-date-time.js"
import { unreachable } from "@brm/util/unreachable.js"
import {
  HStack,
  Icon,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spacer,
  Stack,
  Text,
  Tooltip,
  VStack,
  chakra,
} from "@chakra-ui/react"
import { includeKeys } from "filter-obj"
import type { ReactNode } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import braimLogo from "../../../assets/braim.svg"
import { getDisplayUrl } from "../../util/url.js"
import { Link } from "../Link.js"
import { RenderedMarkdown } from "../RenderedMarkdown.js"
import UserProfileIcon from "../UserProfileAvatar.js"
import { LinkSourceIcon, ListingInformationIcon, SORIcon, ThreeStarsIcon } from "../icons/icons.js"
import { FIELD_SOURCE_COLORS, getDerivedSourceDisplayName } from "./utils.js"

export default function FieldSource({
  fieldMetadata,
  boxSize,
}: {
  fieldMetadata: FieldMetadataWithSuggestions | LegalClausesFieldsMetadata
  boxSize?: number
}) {
  const intl = useIntl()

  let type
  if (!("type" in fieldMetadata)) {
    const clausesMetadata = includeKeys(fieldMetadata, (k) => k in LegalClausesSchema.properties)
    const fieldSourceTypeSet = new Set(
      (Object.values(clausesMetadata) as FieldSourceOutputProperties[]).map((source) => source.type)
    )
    if (fieldSourceTypeSet.size === 0) {
      return null
    }
    if (fieldSourceTypeSet.size === 1) {
      const [first] = fieldSourceTypeSet
      type = first
    } else {
      // Find the first none empty icon type when there are multiple types
      type = Array.from(fieldSourceTypeSet).find((type) => type !== "user")
    }
  } else {
    type = fieldMetadata.type
  }

  if (!type) {
    return null
  }

  // Short circuit for new AI citations popover
  if (type === "listing" && fieldMetadata.rationale && fieldMetadata.links) {
    return (
      <Popover trigger="hover" placement="right-start">
        <PopoverTrigger>
          <Image
            src={braimLogo}
            height={boxSize ? boxSize : 4}
            opacity={0.85}
            display="inline-flex"
            alignItems="center"
          />
        </PopoverTrigger>

        <Portal>
          <PopoverContent bottom={3} width="md">
            <PopoverBody display="flex" flexDirection="column" padding={3} gap={2}>
              <HStack align="start" gap={1}>
                <Text fontWeight="bold">
                  <FormattedMessage
                    id="field.source.listing.popover.summary"
                    description="Tooltip text on BRM global listing field source indicator"
                    defaultMessage="Data gathered by your AI Agent"
                  />
                </Text>
                <Spacer />
                {fieldMetadata?.source_updated_at ? (
                  <Text color="gray.600">{`${intl.formatMessage({
                    id: "field.source.listing.popover.source_updated_at",
                    description: "The date the listing citation was updated",
                    defaultMessage: "Updated",
                  })} ${formatDate(intl, fieldMetadata.source_updated_at)}`}</Text>
                ) : null}
              </HStack>

              {fieldMetadata.links.length > 0 ? (
                <>
                  {/* show the rationale when we have greater than or equal to 2 resources */}
                  {fieldMetadata.links.length >= 2 ? <RenderedMarkdown content={fieldMetadata.rationale} /> : null}

                  <VStack align="start" gap={1}>
                    <Text fontWeight="medium">
                      <FormattedMessage
                        id="field.source.listing.popover.browsed-links"
                        description="Tooltip text on BRM global listing field source indicator"
                        defaultMessage="Websites Accessed"
                      />
                    </Text>

                    <Stack gap={0} maxWidth="100%">
                      {fieldMetadata.links.map((link) => (
                        <Link
                          key={link}
                          to={link}
                          variant="highlighted"
                          target="_blank"
                          rel="noreferrer"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                          overflow="hidden"
                          _hover={{ textDecoration: "underline" }}
                          maxWidth="100%"
                        >
                          {getDisplayUrl(new URL(link))}
                        </Link>
                      ))}
                    </Stack>
                  </VStack>
                </>
              ) : (
                <RenderedMarkdown content={fieldMetadata.rationale} />
              )}
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    )
  }
  let tooltipText: ReactNode = null
  switch (type) {
    case "link":
      tooltipText = intl.formatMessage({
        id: "field.source.link.tooltip",
        description: "Tooltip text on BRM link field source indicator",
        defaultMessage: "Data gathered externally through BRM Link",
      })
      break
    case "listing":
      tooltipText = intl.formatMessage({
        id: "field.source.listing.tooltip",
        description: "Tooltip text on BRM global listing field source indicator",
        defaultMessage: "Data from a BRM standard listing",
      })
      break
    case "sor":
      tooltipText = intl.formatMessage({
        id: "field.source.sor.tooltip",
        description: "Tooltip text on BRM sor field source indicator",
        defaultMessage: "Data from your organization",
      })
      break
    case "document":
    case "transaction":
      tooltipText = intl.formatMessage({
        id: "field.source.extraction.tooltip",
        description: "Tooltip text on BRM extraction field source indicator",
        defaultMessage: "Data automagically extracted by BRM",
      })
      break
    case "derived":
      tooltipText = getDerivedSourceDisplayName(intl, fieldMetadata)
      break
    case "user":
      if (fieldMetadata.source_display_name) {
        if (fieldMetadata.assigned_by_metadata) {
          tooltipText = intl.formatMessage(
            {
              id: "field.source.assignedByUser.tooltip",
              description: "Tooltip text on BRM assigned by user field source indicator",
              defaultMessage: "Manually assigned by {user}",
            },
            {
              user: fieldMetadata.source_display_name,
            }
          )
        } else {
          tooltipText = intl.formatMessage(
            {
              id: "field.source.user.tooltip",
              description: "Tooltip text on BRM user field source indicator",
              defaultMessage: "Data updated by {user}",
            },
            { user: <strong>{fieldMetadata.source_display_name}</strong> }
          )
        }
      }
      break
    default:
      unreachable(type)
  }

  if (!tooltipText) {
    return null
  }

  return (
    <Tooltip label={tooltipText}>
      <chakra.span display="inline-flex" alignItems="center" height="1lh">
        <FieldSourceIcon type={type} userId={fieldMetadata.id} boxSize={boxSize} />
      </chakra.span>
    </Tooltip>
  )
}

function FieldSourceIcon({
  type,
  userId,
  boxSize,
}: {
  type: FieldSourceType
  userId?: string | null
  boxSize?: number
}) {
  if (!type) {
    return null
  }
  const color = `${FIELD_SOURCE_COLORS[type]}.700`
  switch (type) {
    case "link":
      return <Icon as={LinkSourceIcon} color={color} boxSize={boxSize} />
    case "listing":
      return <Icon as={ListingInformationIcon} color={color} boxSize={boxSize} />
    case "sor":
      return <Icon as={SORIcon} boxSize={boxSize} />
    case "document":
    case "transaction":
    case "derived":
      return <Icon as={ThreeStarsIcon} color={color} boxSize={boxSize} />
    case "user":
      if (userId) {
        // Only display the user field source avatar if the field metadata actually has a user id
        return <UserProfileIcon id={userId} boxSize={boxSize} />
      }
      return null
    default:
      return unreachable(type)
  }
}
