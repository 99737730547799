import type {
  CurrencyRange,
  EmployeeGroupType,
  ErpTrackingCategoryType,
  LegalAgreementWithRelations,
  LegalClauses,
  Login,
  ObjectType,
  ToolListing,
  ToolListingPatch,
  ToolOption,
  ToolReference,
  Transaction,
  VendorOption,
  WorkflowRunMinimal,
} from "@brm/schema-types/types.js"
import { LegalClausesSchema } from "@brm/schemas"
import { isStdObjSchema, isToolType } from "@brm/type-helpers/schema.js"
import { isConstSchema, isNullableSchema } from "@brm/util/schema.js"
import { hasOwnProperty, isObject } from "@brm/util/type-guard.js"
import type { JSONSchema, JSONSchemaObject } from "@json-schema-tools/meta-schema"
import type { SchemaOptions } from "@sinclair/typebox"
import type { ValidationRule } from "react-hook-form"
import type { ReadonlyDeep } from "type-fest"

export class UnexpectedSchemaError extends Error {
  public readonly name = "UnexpectedSchemaError"
  constructor(
    message: string,
    public readonly schema: unknown,
    options?: ErrorOptions
  ) {
    super(message, options)
  }
}

export function getPattern(schema: ReadonlyDeep<JSONSchema>): ValidationRule<RegExp> | undefined {
  if (!isObject(schema) || !schema.pattern) {
    return undefined
  }
  const message = getSchemaCustomErrorMessage(schema, "pattern")
  const regexp = new RegExp(schema.pattern, "u")
  if (message) {
    return { value: regexp, message }
  }
  return regexp
}
/** Returns the errorMessage string on the schema if one exists or the error message on the object for the keyword passed in */
export function getSchemaCustomErrorMessage(
  schema: ReadonlyDeep<JSONSchema>,
  keyword: keyof SchemaOptions
): string | undefined {
  if (!isObject(schema)) {
    return undefined
  }
  if (typeof schema.errorMessage === "string") {
    return schema.errorMessage
  }
  return schema.errorMessage?.[keyword]
}

/**
 * Checks whether the given schema is an `EmployeeGroup` with a const `group_type`.
 */
export function isSpecificEmployeeGroupSchema(
  schema: ReadonlyDeep<JSONSchema> | undefined
): schema is ReadonlyDeep<JSONSchemaObject> & {
  properties: { object_type: { const: ObjectType }; group_type: { const: EmployeeGroupType } }
} {
  return (
    isStdObjSchema(schema) &&
    schema.properties.object_type.const === "EmployeeGroup" &&
    isConstSchema(schema.properties.group_type)
  )
}

export function isSpecificErpTrackingCategorySchema(
  schema: ReadonlyDeep<JSONSchema> | undefined
): schema is ReadonlyDeep<JSONSchemaObject> & {
  properties: { object_type: { const: ObjectType }; type: { const: ErpTrackingCategoryType } }
} {
  return (
    isStdObjSchema(schema) &&
    schema.properties.object_type.const === "ErpTrackingCategory" &&
    isConstSchema(schema.properties.type)
  )
}

export function isCurrencyRangeType(
  schema: ReadonlyDeep<JSONSchema> | undefined,
  value?: unknown
): value is CurrencyRange | undefined {
  return (
    isObject(schema) &&
    isObject(schema.properties?.min) &&
    isObject(schema.properties?.max) &&
    isObject(schema.properties.currency_code)
  )
}

export function isVendorOptionType(
  schema: ReadonlyDeep<JSONSchema> | undefined,
  value?: unknown
): value is VendorOption | undefined {
  return (
    isStdObjSchema(schema) &&
    schema.properties.object_type.const === "Vendor" &&
    "id" in schema.properties &&
    isNullableSchema(schema.properties.id)
  )
}
export function isToolReferenceType(
  schema: ReadonlyDeep<JSONSchema> | undefined,
  value?: unknown
): value is ToolReference | undefined {
  return isToolType(schema, value) && isObject(schema) && Object.keys(schema.properties ?? {}).length === 2
}

export function isToolOptionType(
  schema: ReadonlyDeep<JSONSchema> | undefined,
  value?: unknown
): value is ToolOption | undefined {
  return (
    isStdObjSchema(schema) &&
    schema.properties.object_type.const === "Tool" &&
    "id" in schema.properties &&
    isNullableSchema(schema.properties.id)
  )
}

export function isToolOptionsType(
  schema: ReadonlyDeep<JSONSchema> | undefined,
  value?: unknown
): value is ToolOption[] | undefined {
  return (
    isObject(schema) &&
    schema.type === "array" &&
    isObject(schema.items) &&
    !(schema.items instanceof Array) &&
    isToolOptionType(schema.items)
  )
}

export function isToolListingType(schema: ReadonlyDeep<JSONSchema> | undefined, value?: unknown): value is ToolListing {
  return isStdObjSchema(schema) && schema.properties.object_type.const === "ToolListing"
}

export function isLoginType(schema: ReadonlyDeep<JSONSchema> | undefined, value?: unknown): value is Login {
  return isStdObjSchema(schema) && schema.properties.object_type.const === "Login"
}

export function isReconciledTransactionType(
  schema: ReadonlyDeep<JSONSchema> | undefined,
  value?: unknown
): value is Transaction {
  return isStdObjSchema(schema) && schema.properties.object_type.const === "ReconciledTransaction"
}

export function isLegalAgreementType(
  schema: ReadonlyDeep<JSONSchema> | undefined,
  value?: unknown
): value is LegalAgreementWithRelations {
  return isStdObjSchema(schema) && schema.properties.object_type.const === "LegalAgreement"
}

export function pathForLegalAgreement(legalAgreementId: string, showSidebar?: boolean): string {
  return `/agreements/${legalAgreementId}${showSidebar ? "?show_sidebar=true" : ""}`
}

export function matchesLegalAgreementPath(path: string): boolean {
  return path.startsWith("/agreements")
}

export function isWorkflowRunType(
  schema: ReadonlyDeep<JSONSchema> | undefined,
  value?: unknown
): value is WorkflowRunMinimal {
  return isStdObjSchema(schema) && schema.properties.object_type.const === "WorkflowRun"
}

export function pathForWorkflowRun(value: WorkflowRunMinimal): string {
  return `/requests/${value.id}`
}
export function matchesWorkflowRunPath(path: string): boolean {
  return path.startsWith("/requests/")
}

export function isToolListingArrayType(
  schema: ReadonlyDeep<JSONSchema> | undefined,
  value?: unknown
): value is ToolListingPatch[] | undefined {
  return (
    isObject(schema) &&
    schema.type === "array" &&
    isObject(schema.items) &&
    !(schema.items instanceof Array) &&
    isConstSchema(schema.items.properties?.object_type) &&
    schema.items.properties.object_type.const === ("ToolListing" satisfies ObjectType)
  )
}

export function isObjectArrayType(
  schema: ReadonlyDeep<JSONSchema> | undefined,
  value?: unknown
): value is { id: string; object_type: ObjectType }[] | undefined {
  return (
    isObject(schema) &&
    schema.type === "array" &&
    isObject(schema.items) &&
    !(schema.items instanceof Array) &&
    isConstSchema(schema.items.properties?.object_type) &&
    hasOwnProperty(schema.items.properties, "id")
  )
}

/** Checks that the schema has every property expected on the legal clauses schema. No partial LegalClauses objects are valid */
export function isLegalClausesType(
  schema: ReadonlyDeep<JSONSchema> | undefined,
  value: unknown
): value is LegalClauses | undefined {
  const clauseKeys = Object.keys(LegalClausesSchema.properties)
  const schemaProperties = isObject(schema) ? schema?.properties : null
  return Boolean(schemaProperties && clauseKeys.every((key) => schemaProperties[key] !== undefined))
}
