import { hasPermission } from "@brm/schema-helpers/role.js"
import type { ToolDetails, VendorDetails } from "@brm/schema-types/types.js"
import { Button, Flex, Heading, Icon, Stack } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation } from "react-router-dom"
import { useGetUserV1WhoamiQuery } from "../../app/services/generated-api.js"
import EmptyTableState from "../../components/DataTable/EmptyTableState.js"
import { PlusIcon } from "../../components/icons/icons.js"
import { Link } from "../../components/Link.js"
import { filterActiveSubscriptions } from "../../util/legal-agreement.js"
import { LegalAgreementCard } from "./LegalAgreementCard.js"

type Props = { tool: ToolDetails } | { vendor: VendorDetails }

export const LegalAgreementOverview = (props: Props) => {
  const intl = useIntl()
  const location = useLocation()

  const { data: whoami } = useGetUserV1WhoamiQuery()

  const legalAgreements = "tool" in props ? props.tool.legal_agreements : props.vendor.legal_agreements
  const primaryLegalAgreement =
    "tool" in props ? props.tool.primary_legal_agreement : props.vendor.primary_legal_agreement
  const activeSubscriptions = filterActiveSubscriptions(legalAgreements)
  const activeSubscriptionsByTool =
    "tool" in props
      ? { [props.tool.id]: activeSubscriptions }
      : Object.groupBy(activeSubscriptions, (agreement) =>
          "tools" in agreement ? (agreement.tools?.[0]?.id ?? "") : ""
        )
  return (
    <Stack>
      <Heading size="xs">
        <FormattedMessage
          id="legalAgreements.heading"
          description="Heading for agreement cards that are related to the vendor"
          defaultMessage="Agreements"
        />
      </Heading>
      <Stack gap={6}>
        {legalAgreements.length > 0 ? (
          legalAgreements.map((agreement) => {
            const tool = "tool" in props ? props.tool : "tools" in agreement ? agreement.tools[0] : undefined
            const activeSubscriptions = (tool?.id ? activeSubscriptionsByTool[tool.id] : []) ?? []
            const isDuplicate =
              activeSubscriptions.length > 1 && !!activeSubscriptions.find((a) => a.id === agreement.id)
            return (
              <LegalAgreementCard
                isOwner={
                  "tool" in props
                    ? whoami && props.tool.owner?.user_id === whoami.id
                    : whoami && props.vendor.owner?.user_id === whoami.id
                }
                legalAgreement={agreement}
                key={agreement.id}
                isPrimary={agreement.id === primaryLegalAgreement?.id}
                isDuplicate={isDuplicate}
                // If we are on the tool page do now show tool link to avoid confusion
                tool={"tool" in props ? undefined : tool}
              />
            )
          })
        ) : (
          <EmptyTableState
            emptyState={
              hasPermission(whoami?.roles, "legal:create")
                ? intl.formatMessage({
                    id: "legalAgreements.emptyState.canCreate",
                    description:
                      "Text to display instead of the legal agreements list when there are no legal agreements",
                    defaultMessage: "No agreements added. Click “Add agreement” to record an agreement in BRM",
                  })
                : intl.formatMessage({
                    id: "legalAgreements.emptyState",
                    description:
                      "Text to display instead of the legal agreements list when there are no legal agreements and the user has no permission to create legal agreements",
                    defaultMessage: "No agreements added",
                  })
            }
          />
        )}
        {hasPermission(whoami?.roles, "legal:create") && (
          <Flex justifyContent="end">
            <Button
              leftIcon={<Icon as={PlusIcon} />}
              as={Link}
              to={{
                search: location.search,
                pathname: "/agreements/create",
                hash: "vendor" in props ? `#vendor=${props.vendor.id}` : `#tool=${props.tool.id}`,
              }}
              state={{ prevLocation: location }}
            >
              <FormattedMessage
                defaultMessage="Add agreement"
                description="The label for the add legal agreement button in the tools table"
                id="legalAgreements.button.add.label"
              />
            </Button>
          </Flex>
        )}
      </Stack>
    </Stack>
  )
}
