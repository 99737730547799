import type {
  ObjectType,
  StandardObjectElement,
  UserPicker,
  WorkflowBuyerPicker,
  WorkflowSellerPicker,
} from "@brm/schema-types/types.js"
import { displayPersonName } from "@brm/util/names.js"
import {
  Collapse,
  Flex,
  Portal,
  useBoolean,
  useMultiStyleConfig,
  useToast,
  type SystemStyleObject,
  type Theme,
} from "@chakra-ui/react"
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  type KeyboardEventHandler,
} from "react"
import { useIntl } from "react-intl"
import { Editor, Range, Transforms, createEditor } from "slate"
import { withHistory } from "slate-history"
import { Editable, ReactEditor, Slate, withReact, type RenderElementProps, type RenderLeafProps } from "slate-react"
import type { EditableProps } from "slate-react/dist/components/editable.js"
import { usePostImageAssetV1ImageAssetsMutation } from "../../app/services/generated-api.js"
import type { GetLogoForOrganizationProps } from "../../features/workflows/run/utils.js"
import { imageDownloadUrl } from "../../util/document.js"
import { uploadFile } from "../Document/upload-document.js"
import { Truncate } from "../Truncate.js"
import EditorToolbar from "./EditorToolbar.js"
import { Element } from "./Element.js"
import { Leaf } from "./Leaf.js"
import TagPickerList, { type TagPickerListRef } from "./TagPickerList.js"
import type { RichTextEditorProps } from "./types.js"
import { EMPTY_RICH_TEXT_BODY, moveToNextNode } from "./util/common.js"
import { insertFieldTag, withFieldTags } from "./util/field-tag.js"
import { handleImageUploadError, insertImage, withImages } from "./util/image.js"
import { isLinkAtCursorEnd, withInlines } from "./util/inline.js"
import { withShortcuts } from "./util/markdown.js"
import { insertMention, withMentions } from "./util/mentions.js"
import { HOTKEYS, toggleMark } from "./util/rich-text.js"
import { insertStandardObjectTag, withStandardObjectTags } from "./util/standard-object.js"

/**
 * RichTextEditor is a slate-based input component that allows for rich text editing with markdown shortcuts and mentions.
 * isReadOnly: If true, the editor is read-only and does not allow for editing but is still rendered as a tab-focusable input.
 * Focus and error styles are borrow from the chakra-ui Textarea component theme.
 *
 * If you want to display rich text content without the input styles and editing capabilities, use the RichTextDisplay component.
 */
const RichTextEditor = React.memo(
  forwardRef<{ focus: () => void }, RichTextEditorProps & GetLogoForOrganizationProps>(
    (
      {
        initialValue,
        onChange,
        placeholder,
        isReadOnly,
        isPrivate,
        hasError,
        containerProps,
        disableMentions,
        additionalToolbarButtons,
        pickableEntityFilters,
        getLogoToShowByOrganizationId,
        ...editableProps
      },
      ref
    ) => {
      const intl = useIntl()
      const toast = useToast()

      const [uploadImage] = usePostImageAssetV1ImageAssetsMutation()
      const [isDragging, setIsDragging] = useBoolean(false)
      const [isFocused, setIsFocused] = useState(false)

      const onUploadUserImage = useCallback(
        async (file: File) => {
          const uploadableImage = await uploadImage({
            imageAssetInput: {
              file_name: file.name,
              file_size: file.size,
              mime_type: file.type,
            },
          }).unwrap()
          await uploadFile(uploadableImage, file)
          return uploadableImage
        },
        [uploadImage]
      )

      const editor = useMemo(() => {
        return withHistory(
          withFieldTags(
            withMentions(
              withStandardObjectTags(
                withInlines(withImages(withShortcuts(withReact(createEditor())), intl, onUploadUserImage, toast))
              )
            )
          )
        )
      }, [onUploadUserImage, intl, toast])

      /** Mention state */
      const [mentionRange, setMentionRange] = useState<Range | null>(null)
      const mentionSearch = mentionRange ? Editor.string(editor, mentionRange).substring(1) : ""
      const mentionOptionsRef = useRef<TagPickerListRef | null>(null)

      const insertMentionElement = useCallback(
        (selectedMentionOption: UserPicker | WorkflowBuyerPicker | WorkflowSellerPicker) => {
          if (mentionRange) {
            Transforms.select(editor, mentionRange)
            insertMention(
              editor,
              selectedMentionOption.id,
              displayPersonName(selectedMentionOption, intl),
              selectedMentionOption.organization_id
            )
            setMentionRange(null)
            editor.onChange()
          }
        },
        [editor, intl, mentionRange]
      )

      const insertFieldTagElement = useCallback(
        (fieldName: string, objectType: ObjectType, isCustom: boolean) => {
          if (mentionRange) {
            Transforms.select(editor, mentionRange)
            insertFieldTag(editor, fieldName, objectType, isCustom)
          }
          setMentionRange(null)
          editor.onChange()
        },
        [editor, mentionRange]
      )

      const insertStandardObjectElement = useCallback(
        (objectType: StandardObjectElement["object_type"], id: string, display_name: string, website?: string) => {
          if (mentionRange) {
            Transforms.select(editor, mentionRange)
            insertStandardObjectTag(editor, objectType, display_name, id, website)
          }
          setMentionRange(null)
          editor.onChange()
        },
        [editor, mentionRange]
      )

      const richTextContainerRef = useRef<HTMLDivElement>(null)
      useImperativeHandle(ref, () => ({
        focus: () => {
          richTextContainerRef.current?.scrollIntoView({ block: "center" })
          ReactEditor.focus(editor)
          setTimeout(() => {
            Transforms.select(editor, Editor.end(editor, []))
          }, 10)
        },
      }))

      const decorate = useCallback<NonNullable<EditableProps["decorate"]>>(
        ([_, path]) => {
          if (mentionRange) {
            const intersection = Range.intersection(mentionRange, Editor.range(editor, path))

            if (intersection === null) {
              return []
            }

            const range = {
              highlighted: true,
              ...intersection,
            }

            return [range]
          }
          return []
        },
        [editor, mentionRange]
      )

      const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, [])
      const renderElement = useCallback(
        (props: RenderElementProps) => (
          <Element {...props} getLogoToShowByOrganizationId={getLogoToShowByOrganizationId} isReadOnly={isReadOnly} />
        ),
        [getLogoToShowByOrganizationId, isReadOnly]
      )

      const onKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
        (event) => {
          if (event.key === "ArrowRight") {
            if (isLinkAtCursorEnd(editor)) {
              event.preventDefault()
              moveToNextNode(editor)
              return
            }
          }

          if (event.code === "Space") {
            if (isLinkAtCursorEnd(editor)) {
              moveToNextNode(editor)
              return
            }
          }

          // Meta key hotkeys for applying marks to the current selection
          if (event.key && (event.metaKey || event.ctrlKey)) {
            const mark = HOTKEYS[event.key]
            if (mark) {
              event.preventDefault()
              toggleMark(editor, mark)
            }
            return
          }

          if (event.key === "@" && !disableMentions) {
            if (!mentionRange && editor.selection && Range.isCollapsed(editor.selection)) {
              const characterBeforePoint = Editor.before(editor, editor.selection.anchor, { unit: "character" })
              const characterBeforeRange =
                characterBeforePoint && Editor.range(editor, characterBeforePoint, editor.selection.anchor)
              const characterBefore = characterBeforeRange && Editor.string(editor, characterBeforeRange)
              // If the user types @ and the user selection is collapsed, set the current position as the mention range
              if (!characterBefore || /\s/u.test(characterBefore)) {
                const newRange = Editor.range(
                  editor,
                  editor.selection.anchor,
                  Editor.after(editor, editor.selection.anchor, { unit: "character" })
                )
                setMentionRange(newRange)
              }
            }
            return
          }

          if (mentionRange) {
            // Arrow key navigation and selection of mention options
            switch (event.key) {
              case "ArrowDown": {
                event.preventDefault()
                mentionOptionsRef.current?.incrementIndex()
                break
              }
              case "ArrowUp": {
                event.preventDefault()
                mentionOptionsRef.current?.decrementIndex()
                break
              }
              case "Tab":
              case "Enter": {
                event.preventDefault()
                mentionOptionsRef.current?.selectCurrent()
                setMentionRange(null)
                break
              }
              case "Escape": {
                setMentionRange(null)
                break
              }
            }
          }

          // Prevent the enter key from accidentally submitting forms when the user intends to create a newline
          if (event.key === "Enter") {
            event.stopPropagation()
          }
        },
        [editor, setMentionRange, mentionRange, disableMentions]
      )

      const textAreaStyleConfig = useMultiStyleConfig("Textarea", {
        size: "sm",
      }) as NonNullable<Theme["components"]["Textarea"]["baseStyle"]>

      const editorContent = (
        <Slate
          editor={editor}
          initialValue={initialValue ?? EMPTY_RICH_TEXT_BODY}
          onSelectionChange={(newSelection) => {
            if (!mentionRange) {
              return
            }
            if (!newSelection) {
              setMentionRange(null)
              return
            }
            const intersecting = Range.intersection(newSelection, mentionRange)
            if (!intersecting) {
              setMentionRange(null)
            }
          }}
          onValueChange={(value) => {
            onChange?.(value)
            // Check if the mention range should change
            if (mentionRange) {
              if (editor.selection && Range.isCollapsed(editor.selection)) {
                const cursorPoint = editor.selection.anchor
                if (cursorPoint) {
                  // The potential new mention range is the current mention range start up to the cursor
                  const newRange = Editor.range(editor, mentionRange.anchor, cursorPoint)
                  const mentionText = Editor.string(editor, newRange)
                  if (mentionText.startsWith("@")) {
                    setMentionRange(newRange)
                  } else {
                    // If the @ has been deleted, clear the mention range
                    setMentionRange(null)
                  }
                }
              }
            }
          }}
        >
          <Editable
            decorate={decorate}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={placeholder}
            style={{
              outline: "none",
              boxShadow: "none",
              background: !isReadOnly && isDragging ? "var(--chakra-colors-brand-50)" : "inherit",
            }}
            spellCheck
            onFocus={() => setIsFocused(true)}
            onBlur={(event) => {
              // If an input is in a popover, prevent popover from closing when clicking on mention options
              if (event.relatedTarget?.matches(".mention-option")) {
                event.stopPropagation()
              } else {
                setMentionRange(null)
                setIsFocused(false)
              }
            }}
            tabIndex={0}
            onKeyDown={onKeyDown}
            onDragOver={setIsDragging.on}
            onDragLeave={setIsDragging.off}
            onDrop={async (event) => {
              if (isReadOnly) {
                return
              }
              event.preventDefault()
              setIsDragging.off()
              const file = event.dataTransfer.files[0]
              if (!file) {
                return
              }
              try {
                const uploadedImage = await onUploadUserImage(file)
                insertImage(editor, imageDownloadUrl(uploadedImage.id), file.name)
              } catch (err) {
                handleImageUploadError(err, toast, intl)
              }
            }}
            readOnly={isReadOnly}
            aria-readonly={isReadOnly}
            {...editableProps}
          />

          <Collapse in={!isReadOnly && isFocused} animateOpacity>
            <EditorToolbar additionalButtons={additionalToolbarButtons} mt={1} />
          </Collapse>
          {mentionRange && (
            <Portal>
              <TagPickerList
                ref={mentionOptionsRef}
                mentionSearch={mentionSearch}
                mentionRange={mentionRange}
                resetMentionRange={() => setMentionRange(null)}
                insertMentionElement={insertMentionElement}
                insertFieldTagElement={insertFieldTagElement}
                insertStandardObjectElement={insertStandardObjectElement}
                getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
                pickableEntityFilters={pickableEntityFilters}
              />
            </Portal>
          )}
        </Slate>
      )

      const boxStyles: SystemStyleObject = {
        ...textAreaStyleConfig,
        ...(isReadOnly
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (textAreaStyleConfig as any)._readOnly
          : hasError
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (textAreaStyleConfig as any)._invalid
            : {}),
        "&:focus, &:focus-within": {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...(textAreaStyleConfig as any)["&:focus, &:focus-within"],
          ...(isReadOnly
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (textAreaStyleConfig as any)._readOnly._focus
            : hasError
              ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (textAreaStyleConfig as any)._invalid
              : {}),
        },
        // Textarea styles have some intrinsic height we will override
        minHeight: undefined,
        height: undefined,
        // We set the padding on the box conditionally below. ignore the textarea style
        paddingY: undefined,
      }

      return (
        <Flex
          flexDir="column"
          ref={richTextContainerRef}
          px={3}
          pt={2}
          // Less bottom padding when the tool bar is rendered
          pb={!isReadOnly && isFocused ? 1 : 2}
          position="relative"
          // Explicitly checking private since undefined should have a different background from false
          background={isPrivate === false ? "blue.50" : "white"}
          sx={boxStyles}
          {...containerProps}
        >
          {isReadOnly ? <Truncate noOfLines={5}>{editorContent}</Truncate> : editorContent}
        </Flex>
      )
    }
  )
)

export default RichTextEditor
