import type { TableIdentifier, WorkflowRunListItem, WorkflowRunStatus } from "@brm/schema-types/types.js"
import type { Filter } from "@brm/type-helpers/filters.js"
import type { BRMPaths } from "@brm/type-helpers/paths.js"
import type { TableParamsState } from "../../util/schema-table.js"

export const WORKFLOW_RUN_TABLE_ID: TableIdentifier = "workflow-runs"
export const WORKFLOW_RUN_TABLE_DEFAULT_PARAMS: Partial<TableParamsState<string>> = {
  filterMap: new Map<string, Filter>([
    [
      "progress.status" satisfies BRMPaths<WorkflowRunListItem>,
      {
        column: "progress.status" satisfies BRMPaths<WorkflowRunListItem>,
        fields: { comparator: "any", values: ["in_progress"] satisfies WorkflowRunStatus[] },
      },
    ],
  ]),
  sorting: [{ id: "target_date" satisfies BRMPaths<WorkflowRunListItem>, desc: false }],
}

// This needs to be kept in sync with api/src/controller/notification/helpers/inbox-notifications.ts
export const AUTO_SELECT_STEP_HASH = "auto_select_step"

export const AUTO_SAVE_DEBOUNCE_MS = 1000
